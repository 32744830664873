.modal-typeface-family-case-study {
  & .modal__content {
    height: 100%;
  }

  & .modal__content > div {
    margin-top: 30px;
    padding: 10px;
    overflow: hidden;
  }

  &-content {
    flex: 1;
    @apply mx-auto overflow-y-scroll;
  }

  &-wrapper {
    height: 100%;

    img {
      max-height: 100%;
      height: 100%;
      margin: auto;
      object-fit: scale-down;
    }
  }

  & header {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 10px;
    @apply bg-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: calc(var(--navbar-height) + 1rem);

    & .logo {
      height: auto;

      & svg {
        width: 52px;
        height: 34px;
      }
    }

    & .close {
      display: flex;
      align-items: center;
      @apply text-grey-dark;

      span {
        display: none;
      }

      & svg {
        width: 18px;
        height: 18px;
        padding: 8px;
        box-sizing: content-box;
      }
    }
  }

  .glyph-content {
    height: calc(100vh - 61px);
    width: 100%;

    @screen desktop {
      height: 100%;
    }
  }

  .modal-arrows {
    height: 0 !important;
    margin: 0 !important;
  }

  .modal-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    border: 1px solid #c2c2c2;
    @apply bg-white;

    svg {
      width: 1.2rem;
      height: 1rem;
      margin: auto;
    }

    &--previous {
      left: 1rem;

      svg {
        transform: rotateZ(180deg);
      }
    }

    &--next {
      right: 1rem;
    }

    @screen desktop {
      width: 5rem;
      height: 5rem;

      svg {
        width: 2rem;
        height: 1.5rem;
      }

      &--previous {
        left: 7.6rem;
      }

      &--next {
        right: 7.6rem;
      }
    }
  }
}
