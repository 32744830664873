.family-case-study-sidebar {
  @apply desktop:w-2/6;
  flex: none;

  @screen desktop {
    margin-right: 4rem;
  }

  &__block {
    padding: 1.6rem 0;
    @apply border-t border-grey-light;

    @screen desktop {
      padding: 1.4rem 0;
    }

    &__title {
      font-size: 1.2rem;
      line-height: 2rem;
      @apply font-allumi;
      @apply text-grey-dark;
    }

    &:first-of-type {
      @apply mt-8;
      @apply border-t-0;
    }
  }

  &__block--licenses {
    p span + span {
      margin-left: 0.5rem;
    }
  }
}
