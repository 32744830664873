.add-checkbox-button {
  input {
    display: none;
  }

  svg {
    width: 2.7rem;
    height: 2.7rem;
  }

  .icon-add-checkbox {
    color: #c2c2c2;
  }

  .icon-add-checkbox-checked {
    display: none;
  }

  /* States */
  &:hover {
    cursor: pointer;

    .icon-add-checkbox {
      @apply text-grey-dark;
    }
  }

  input:checked ~ .icon-add-checkbox {
    display: none;
  }

  input:checked ~ .icon-add-checkbox-checked {
    display: block;
  }
}
