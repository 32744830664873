.admin-edit-button {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.5rem 2rem;
  @apply bg-brown;
  @apply text-white;
  @apply border-t border-r border-white;
  z-index: 10;

  &.with-cart-banner {
    margin-bottom: var(--cart-banner-height);
  }
}
