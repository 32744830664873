.help-show {
  &__content {
    @screen tablet-portrait {
      margin-left: calc(33.33333% + 2rem);
      width: calc(66.66667% - 4rem);
    }

    .title {
      @apply mb-6 desktop:mb-14;
      font-size: 2.2rem;
      line-height: 2.2rem;

      @screen tablet {
        font-size: 4.6rem;
        line-height: 4.6rem;
      }
    }
  }

  .sections {
    margin-top: 3.6rem;
    margin-bottom: 1rem;
    @apply w-full;

    @screen desktop {
      margin-top: 7.6rem;
    }

    .section {
      margin-bottom: 1.2rem;
      padding-top: 1.3rem;
      @apply border-t border-grey-light;

      button {
        @apply w-full;
        @apply inline-flex flex-wrap items-center;
        margin-bottom: 1rem;
        @apply font-ysans-demi;

        svg {
          width: 1.3rem;
          height: 0.8rem;
          margin-left: 1rem;
          margin-top: 0.7rem;
          transform: rotate(0deg);
        }
      }

      &__title {
        @apply text-left;
        font-size: 1.8rem;
        line-height: 2.2rem;
        max-width: 85%;

        @screen tablet-portrait {
          max-width: 90%;
        }

        @screen tablet {
          max-width: 95%;
        }

        @screen desktop {
          font-size: 2.5rem;
          line-height: 2.5rem;
        }

        h2 {
          @apply font-mencken-head;
          font-size: 2.2rem;
          line-height: 2.8rem;

          @screen desktop {
            font-size: 3.2rem;
            line-height: 3.8rem;
          }
        }

        h3 {
          @apply font-ysans-demi;
        }
      }

      &__content {
        font-size: 1.4rem;
        line-height: 2.5rem;
        margin-left: 2.2rem;

        @screen desktop {
          font-size: 1.8rem;
          line-height: 2.9rem;
          letter-spacing: 0.04rem;
          margin-left: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 1.6rem;
          @apply font-ysans-demi;

          @screen desktop {
            font-size: 2.2rem;
          }
        }
      }

      /* States */
      &.has-revealed {
        button {
          @apply mb-6 desktop:mb-12;

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
