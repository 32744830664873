@media print {
  * {
    margin: 0;
    padding: 0;
  }

  @page {
    size: A4;
    margin: 2cm;
  }

  html {
    width: 1100px;
  }

  body {
    font: 13pt;
  }

  .main,
  .container {
    @apply w-full;
    margin: 0;
    float: none;
  }

  h1 {
    font-size: 24pt;
  }

  h2,
  h3,
  h4 {
    font-size: 14pt;
  }

  a {
    &[href^="http"]:after {
      content: " <" attr(href) "> ";
    }
  }

  :not(:local-link):after {
    content: " <" attr(href) "> ";
  }

  blockquote {
    break-inside: avoid;
  }

  img {
    break-inside: avoid;
    display: block;
  }

  table,
  pre {
    break-inside: avoid;
  }

  ul,
  ol,
  dl {
    break-before: avoid;
  }

  li {
    line-height: 1.6em;
  }

  header,
  footer,
  .prefooter,
  .cart-banner,
  .admin-edit-button,
  .admin-show-translation-keys-button,
  .cart-element-header,
  .cart-element-footer,
  .cart-element-extras,
  .help-button,
  .account-user-menu,
  .segment-tabs,
  .help-header__container {
    display: none !important;
  }

  .main {
    margin-top: 4rem;
  }

  .logo-print-container {
    @apply !block;
    position: absolute;

    svg {
      width: 200px;
      height: 60px;
    }
  }

  /* Checkout */
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .payment .your-order,
  .checkout .your-order,
  .account-carts-show .your-order {
    grid-column: span 2 / span 2;
  }

  .print-informations {
    @apply !mt-32 !block;
  }

  /* Account Content */
  .account_content {
    .content-block {
      @apply w-full;
    }
  }
}
