.format-selector {
  @apply inline-flex items-center;
  height: 2.7rem;

  input {
    display: none;
  }

  label {
    width: 4rem;
    font-size: 1rem;
    line-height: 2.5rem;
    letter-spacing: 0.1rem;
    @apply text-grey;
    @apply border;
    @apply text-center font-allumi uppercase;

    &:hover {
      @apply border-grey text-brown;
      cursor: pointer;
    }
  }

  input:checked + label {
    @apply text-brown;
  }

  label:first-of-type {
    z-index: 1;
  }

  label:last-of-type {
    margin-left: -1px;
    z-index: 2;
  }

  input:checked + label {
    @apply border-brown;
    z-index: 3;
  }
}
