@import "tailwindcss/base";

@import "rangeslider-pure/dist/range-slider.min.css";
@import "swiper/swiper-bundle.min.css";

@import "../stylesheets";

.turbo-progress-bar {
  height: 0.5rem;
  @apply bg-red;
}
