.typeface-variations-show {
  .variations {
    .toolbar {
      .toolbar-compare {
        display: inline-flex;
      }
    }

    .section-compare textarea {
      width: 100%;
      height: 100%;
      overflow: hidden;
      color: #000;
      outline: 0;
      margin-bottom: 0.5em;
      line-height: 1em;
      -webkit-font-kerning: normal;
      font-kerning: normal;
      border: none;
      resize: none;
      will-change: height, font-size, line-height;

      &:nth-child(1) {
        font-size: 4.4rem;
        line-height: 4rem;

        @screen desktop {
          font-size: 13.2rem;
          line-height: 13.2rem;
          margin-bottom: 0.68em;
        }
      }

      &:nth-child(2) {
        font-size: 3.5rem;
        line-height: 4rem;

        @screen desktop {
          font-size: 5.2rem;
          line-height: 7.5rem;
          margin-bottom: 0.63em;
        }
      }

      &:nth-child(3) {
        font-size: 2rem;
        line-height: 2.8rem;

        @screen desktop {
          font-size: 3.1rem;
          line-height: 3.7rem;
          margin-right: 5%;
        }
      }

      &:nth-child(4) {
        font-size: 1.6rem;
        line-height: 2.5rem;

        @screen desktop {
          font-size: 2rem;
          line-height: 2.7rem;
        }
      }

      @screen desktop {
        &:nth-child(3),
        &:nth-child(4) {
          width: 45%;
          flex: 1 1 auto;
        }
      }
    }

    .section-compare:last-of-type {
      & > div {
        height: 100%;
        @apply justify-between desktop:flex;
      }

      div textarea {
        height: 100%;
        @apply w-full desktop:w-1/2;

        &:first-of-type {
          @apply mb-16 desktop:mb-0;
          @apply desktop:mr-16;
        }
      }
    }
  }
}
