.markdown {
  font-kerning: normal;
  -webkit-font-kerning: normal;

  font-feature-settings: "kern" 1, "liga" 1, "onum" 1;

  p {
    @apply font-mencken-text;
    @apply text-brown;
    font-size: 1.4rem;
    line-height: 1.78;
    letter-spacing: 0.04rem;
    margin-bottom: 2rem;

    @screen desktop {
      font-size: 1.8rem;
      line-height: 2.9rem;
      letter-spacing: 0.045rem;
      margin-bottom: 2.4rem;
    }
  }

  h1 {
    @apply font-ysans-demi;
    font-size: 2.2rem !important;
    letter-spacing: 0;
    margin-bottom: 1.2rem;

    font-weight: normal;
    line-height: 1;

    @screen desktop {
      font-size: 5rem !important;
      letter-spacing: -0.5px;
      margin-bottom: 18px;
    }
  }

  h2 {
    @apply border-t border-grey-light;
    @apply font-mencken-head !important;
    font-size: 2.2rem !important;
    letter-spacing: 0;
    margin-top: 4rem;
    margin-bottom: 1rem;
    padding: 1rem 0 0;
    font-weight: bold;
    line-height: 1.2;

    @screen desktop {
      font-size: 3.2rem !important;
      padding: 1.2rem 0 0;
      margin-top: 6rem;
      margin-bottom: 1.4rem;
    }
  }

  h3 {
    @apply font-mencken-head !important;
    font-size: 2.2rem !important;
    letter-spacing: 0;
    margin-top: 2rem;
    margin-bottom: 1rem;
    line-height: 1.2;

    @screen desktop {
      font-size: 3.2rem !important;
      margin-bottom: 1.4rem;
    }
  }

  h4 {
    @apply font-ysans-demi;
    font-size: 1.8rem !important;
    line-height: 1.22;
    margin-bottom: 0.8rem;

    @screen desktop {
      font-size: 2.5rem !important;
      line-height: 1.2;
      margin-bottom: 1.2rem;
    }
  }

  h5 {
    @apply font-ysans-demi;
    font-size: 1.6rem !important;
    line-height: 1.33;
    margin-bottom: 0.8rem;

    @screen desktop {
      font-size: 2.1rem !important;
      line-height: 1.14;
      margin-bottom: 1.2rem;
    }
  }

  h6 {
    @apply font-ysans-demi;
    font-size: 1.3rem;
    line-height: 1.38;
    letter-spacing: 1.5px;
    margin-bottom: 0.8rem;

    @screen desktop {
      font-size: 1.7rem;
      line-height: 1.18;
      letter-spacing: 1.8px;
      text-transform: uppercase;
      margin-bottom: 1.2rem;
    }
  }

  strong {
    font-weight: bold;
  }

  ul,
  ol {
    margin-bottom: 2.4rem;

    li {
      @apply font-mencken-text;
      font-size: 1.4rem;
      line-height: 1.79;
      letter-spacing: 0.35px;
      position: relative;
      margin-bottom: 0.8rem;

      @screen desktop {
        font-size: 1.8rem;
        line-height: 1.61;
      }
    }
  }

  ul li:before {
    content: "→";
    display: block;
    position: absolute;
    left: -23px;
    top: -2px;
    margin-top: 2px;
  }

  ol {
    counter-reset: number-counter;

    li:before {
      content: counter(number-counter) ".";
      counter-increment: number-counter;
      line-height: 1.56;
      font-size: 1.4rem;
      position: relative;
      display: block;
      position: absolute;
      right: calc(100% + 6px);
      top: 0px;

      @screen desktop {
        font-size: 1.8rem;
        line-height: 1.61;
      }
    }
  }

  img {
    max-width: 100%;
    display: block;
    /* width: 100%; Removed to improve small images display on Articles */
  }

  blockquote,
  q {
    @apply border-l-4 border-grey-light;
    padding-left: 1rem;
    margin-bottom: 2rem;

    @screen desktop {
      padding-left: 2rem;
      margin-bottom: 5rem;
    }
  }

  table {
    margin-bottom: 2rem;
    overflow: scroll;
    max-width: 100%;
    display: block;

    @screen desktop {
      margin-bottom: 5rem;
      overflow: auto;
    }

    tr:last-child td {
      padding-bottom: 2rem;
      @apply border-b border-grey-light;
    }

    th {
      @apply font-ysans-demi;
      @apply text-grey-dark;
      @apply border-b border-grey-light;
      font-size: 1.2rem;
      line-height: 1.7;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      text-align: left;
      padding-right: 0.5rem;
      padding-bottom: 1.2rem;
      margin-bottom: 0.2rem;
      min-width: 14rem;

      &:last-child {
        padding-right: 0;
      }

      @screen desktop {
        font-size: 1.4rem;
        line-height: 1.43;
        padding-right: 0;
      }
    }

    td {
      @apply text-brown;
      font-size: 1.4rem;
      line-height: 1.7;
      letter-spacing: 0.4px;
      text-align: left;
      font-feature-settings: "tnum" 1, "lnum" 1;
      padding-top: 1.4rem;
      padding-right: 1rem;
      min-width: 14rem;

      @screen desktop {
        font-size: 1.6rem;
        line-height: 1.25;
        padding-right: 3.8rem;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  p.image-full-width {
    img {
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      max-width: 100vw;
      width: 100vw;

      @screen tablet-portrait {
        max-width: 100%;
        width: 100%;
        position: static;
        margin: 0;
      }
    }

    @screen tablet-portrait {
      margin-left: calc(-50%);
      margin-right: 0;
    }
  }

  .image-caption {
    @apply font-allumi;
    @apply text-grey-dark;
    display: block;
    font-size: 1.2rem;
    line-height: 1;
    margin-top: 0.3rem;
    font-style: italic;
    letter-spacing: 0.5px;
    text-align: left;

    @screen desktop {
      font-size: 1.4rem;
      line-height: 1.43;
    }

    em {
      font-style: normal;
    }
  }

  .slider {
    &.full-width {
      max-width: 100vw;
      width: 100vw;
      margin-left: -3.5rem;
      margin-right: -2.5rem;

      @screen tablet-portrait {
        max-width: calc(var(--grid-max-width) + var(--grid-gap) * 2);
        width: auto;
        margin-left: calc(-50%);
        margin-right: 0;
      }

      .swiper-slide {
        img {
          max-width: 100%;
        }
      }

      span.image-caption {
        margin-left: 3.5rem;

        @screen tablet-portrait {
          margin-left: 0;
        }
      }
    }

    margin-bottom: 2rem;

    @screen desktop {
      margin-bottom: 5rem;
    }

    .swiper-button-next {
      right: 1rem;
    }

    .swiper-button-prev {
      left: 1rem;
    }
  }

  .footnotes {
    hr {
      display: none;
    }

    ol {
      li:before {
        @apply font-allumi;
        content: counter(number-counter);
        font-feature-settings: "sups" 1;
        font-size: 1.4rem;
        line-height: 1.67;
        right: calc(100% + 5px);

        @screen desktop {
          font-size: 1.6rem;
          line-height: 1.72;
        }
      }
    }

    p {
      @apply font-allumi;
      font-size: 1.4rem;
      line-height: 1.56;
      margin-bottom: 0;

      @screen desktop {
        font-size: 1.6rem;
        line-height: 1.69;
      }
    }
  }

  .block-with-emphase {
    @apply relative desktop:pl-80;

    h2 {
      @apply hidden;
      @apply absolute m-0 w-3/5 p-0;
      @apply text-right !font-mencken-compress uppercase text-grey;

      @screen desktop {
        display: block;
        padding: 0;
        margin: 0;
        top: 3.8rem;
        right: calc(100% - 10rem);
        border: none;
        font-size: 6rem !important;
        font-weight: bold;
        line-height: 1;
      }
    }
  }

  .video-container {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin-bottom: 2rem;

    @screen desktop {
      margin-bottom: 2.4rem;
    }

    & > iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}
