.carousel {
  overflow: hidden;
  width: 100%;

  .swiper-slide {
    width: 100% !important;
  }

  .swiper-slide {
    img {
      max-width: var(--grid-max-width);
      margin-left: auto;
      margin-right: auto;
      height: auto;
    }
  }

  .slider-button {
    @apply absolute;
    @apply bottom-4 desktop:bottom-20;
    @apply w-full text-center;
    z-index: 11;

    a.button {
      @apply inline-block;
      font-size: 1rem;
      letter-spacing: 0.83px;
      line-height: 1rem;
      padding: 0.6rem 0.8rem;
      min-width: 6.4rem;
      min-height: 2.4rem;
      box-shadow: 4px 6px 8px 0 rgba(0, 0, 0, 0.1);

      @screen desktop {
        font-size: 1.6rem;
        line-height: 1.6rem;
        min-height: 2.2rem;
        padding: 1rem 1.4rem;
      }
    }
  }
}

.carousel-btn-prev,
.carousel-btn-next {
  @apply flex;
  @apply bg-grey bg-opacity-40;
  @apply text-brown;
  opacity: 0.5;
  width: 4rem;
  height: 4rem;
  border-radius: 25px;

  @screen desktop {
    width: 5rem;
    height: 5rem;
  }
}

.carousel-btn-prev:hover,
.carousel-btn-next:hover {
  opacity: 1;
}

.carousel-btn-prev:after,
.carousel-btn-next:after {
  content: none;
}

.carousel-btn-prev svg,
.carousel-btn-next svg {
  width: 1.4rem;

  @screen desktop {
    width: 1.8rem;
  }
}

.carousel-btn-prev svg {
  transform: rotate(180deg);
}

.carousel .swiper-pagination {
  @apply hidden desktop:block;
}

.carousel .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  border: 4px solid white;
  @apply bg-red;
}
