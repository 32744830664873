.font-licenses {
  @apply flex flex-col items-start tablet:flex-row tablet:items-center;
  @apply w-1/2 tablet:w-2/3;

  &__list {
    overflow: hidden;
    height: 2.7rem;

    @screen tablet {
      height: auto;
    }
  }

  .font-license-change {
    font-size: 1.2rem;
    line-height: 2.7rem;
    letter-spacing: 0.08rem;
    @apply font-allumi uppercase text-grey-dark;
    @apply inline-flex items-center;
    @apply tablet:hidden;

    .icon {
      margin-left: 1rem;
      margin-top: 1px;
      width: 0.8rem;
      height: 0.5rem;
    }
  }

  .font-license {
    display: block;
    font-size: 1.2rem;
    line-height: 2.7rem;
    margin-bottom: 0.5rem;
    letter-spacing: 0.08rem;
    @apply text-grey;
    @apply font-allumi uppercase;
    width: 100%;
    order: 1;

    input + label {
      display: none;
    }

    input:checked + label {
      display: block;
    }

    @screen tablet {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 3.8rem;
      width: auto;

      input + label {
        display: initial;
      }
    }

    @screen desktop {
      font-size: 1.6rem;
      margin-right: 7.6rem;
    }

    &:hover {
      @apply text-brown;
    }

    div:first-child {
      display: inline-block;
    }

    &--empty {
      order: 5;
      flex: 1 1 auto;
      height: 100%;

      @screen tablet {
        display: none;
      }
    }
  }

  /* States */
  &.is-opened {
    .font-license-change .icon {
      transform: rotate(180deg);
    }

    .font-license input + label {
      display: inline;
    }

    .font-licenses__list {
      position: absolute;
      display: flex;
      top: 2.7rem;
      left: 0;
      right: 0;
      padding-left: var(--container-padding);
      padding-right: var(--container-padding);
      width: 100%;
      height: 100vh;
      background-color: rgba(255, 255, 255, 0.95);
      z-index: 10;
      flex-direction: column;
    }
  }
}
