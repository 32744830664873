.typeface-segments-details {
  .details-main,
  .details-sidebar {
    @screen desktop {
      margin-top: 1rem;
    }
  }

  .details-main {
    @apply border-t border-grey-light;
    padding-top: 1rem;
    @apply desktop:w-4/6;

    @screen desktop {
      border-top: none;
      padding-top: 0rem;
    }

    .content-container {
      padding-left: 2.6rem;

      @screen desktop {
        padding-left: 0;
      }
    }

    .markdown-title {
      margin-bottom: 1.7rem;
      font-size: 1.5rem;
      line-height: 2rem;
      @apply font-ysans-demi;

      @screen desktop {
        margin-bottom: 2.2rem;
        font-size: 2.5rem;
      }
    }

    .slider {
      margin-top: 1.6rem;
      margin-bottom: 2.8rem;

      @screen desktop {
        margin-top: 0;
        margin-bottom: 4.8rem;
      }
    }

    .markdown {
      font-size: 1.4rem;
      line-height: 2.5rem;
      letter-spacing: 0.035rem;
      margin-left: 2.6rem;
      @apply text-grey-dark desktop:text-brown;

      /* copy of subtitle-e */
      h5 {
        @apply font-ysans-demi;
        font-feature-settings: "onum" 1, "pnum" 1;
        font-size: 1.5rem;
        line-height: 2rem;
        margin-top: 2rem;
        margin-bottom: 0.6rem;

        @screen desktop {
          font-size: 2.1rem;
          line-height: 2.4rem;
          margin-top: 3rem;
          margin-bottom: 1.4rem;
        }
      }

      @screen desktop {
        font-size: 1.8rem;
        line-height: 2.9rem;
        letter-spacing: 0.045rem;
        margin-left: 0;
      }
    }
  }

  .content-block {
    padding: 1.6rem 1rem;
    margin-left: 2.6rem;
    margin-right: 2.6rem;
    margin-top: 1.6rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
    @apply bg-grey-field;
    @apply border border-grey-light;

    li {
      @apply max-w-max;
    }

    p {
      margin-bottom: 1.6rem;

      @screen desktop {
        margin-bottom: 2rem;
      }
    }

    img {
      width: 100%;
      height: auto;
      max-width: 100%;
      object-fit: fill;
      object-position: center;
      background-color: #d8d8d8;
      border: 1px solid #c2c2c2;
    }

    a {
      display: block;
      margin-top: 1rem;

      .button {
        min-width: 13.3rem;
      }

      @screen desktop {
        margin-top: 2rem;

        .button {
          font-size: 1.2rem;
          min-height: 2.7rem;
        }
      }
    }

    @screen desktop {
      margin-top: 8.8rem;
      padding: 2.7rem 2rem;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .font-pairings {
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding-top: 0.5rem;
    padding-left: 2.6rem; /* minus 1rem from main container */
    padding-right: 2.6rem; /* minus 1rem from main container */
    @apply border-t border-grey-light;

    @screen desktop {
      padding-top: 1.3rem;
      margin-bottom: 8rem;
      padding-left: 0;
      padding-right: 0;
    }

    @screen desktop {
      margin-top: 8.8rem;
    }
  }
}
