.cart-element-payment {
  @apply pb-8;

  header {
    margin-bottom: 1.5rem;
    @apply flex items-center justify-between;

    h2 {
      @screen desktop {
        font-size: 1.5rem;
        line-height: 2.7rem;
      }
    }

    p {
      font-size: 1.2rem;
      line-height: 1.9rem;
      @apply inline-flex items-end;
      @apply font-allumi text-grey-dark;
    }
  }

  .kr-smart-form,
  .kr-smart-form[krvue] {
    @apply w-full;

    .kr-smart-form-embedded-header {
      display: none;
    }

    .kr-methods-list-row {
      &:first-child {
        @apply mb-4 border-b border-grey-light;
        padding-bottom: 5.5rem;
      }

      &:not(:first-child) {
        .kr-smart-form-list-section-name {
          display: none;
        }
      }

      .kr-smart-form-list-section-name {
        @apply font-ysans-demi;
        @apply border-b border-grey-light pb-4;

        font-size: 1.5rem;
        line-height: 1.9rem;

        margin-bottom: 1.5rem;

        @screen desktop {
          font-size: 1.5rem;
          line-height: 2.7rem;
        }
      }
    }

    .kr-smart-form-wallet-card {
      @apply w-full;
      @apply ml-auto;
      border-radius: 8px !important;
      padding: 0 1rem !important;
      min-height: 36px !important;
      height: auto !important;
      text-align: center !important;
      @apply font-allumi !important;
      font-size: 1.4rem !important;
      line-height: 1.4 !important;
      @apply text-grey-dark;

      .kr-brand-icon {
        @apply mr-4;
      }

      .kr-wallet-method-label {
        @apply mr-2;
      }

      .kr-delete-button-wrapper {
        @apply ml-auto;

        svg {
          @apply fill-current;
          height: 1.4rem;
          width: 1.4rem;
        }

        &:hover {
          @apply text-dark-red;
        }
      }
    }

    .kr-wallet-tabs {
      @apply mb-4 border-b border-grey-light;

      .kr-my-cards,
      .kr-new-card {
        @apply items-start py-4 font-ysans-demi text-grey;
        font-size: 1.5rem;

        &.kr-selected {
          @apply bg-white text-brown;

          &:hover {
            @apply text-black;
          }
        }

        &:hover {
          @apply text-grey-dark;
        }
      }
    }

    .kr-card-list-wrapper {
      @apply mb-4;

      .kr-card-element {
        @apply my-4;

        .kr-card-label {
          input[type="radio"] {
            @apply mr-2;
          }

          label {
            @apply -mt-2;
          }
        }
      }
    }

    .kr-card-holder-name,
    .kr-pan,
    .kr-expiry,
    .kr-security-code {
      font-size: 1.4rem;
      letter-spacing: 0.034rem;
      line-height: 2.7rem;
      padding: 0.8rem 1.6rem;
      min-height: 3.6rem;
      max-height: 3.6rem;
      @apply ml-0 w-full bg-grey-field font-mencken-text text-brown;

      .kr-icon-wrapper-root,
      .kr-field-component {
        background-color: transparent !important;
        height: 100%;
      }

      .kr-iframe-wrapper {
        height: 100%;
      }

      .kr-field-wrapper,
      .kr-outer-wrapper {
      }

      .kr-input-field {
        border: none;
        background-color: transparent !important;
        outline: 0;
        &::placeholder {
          @apply text-grey-dark text-opacity-70;
        }
      }

      &.kr-error {
        @apply border-l-4 border-red;
        padding-left: 1.2rem;
      }
    }

    .kr-card-holder-name {
      .kr-field-component {
        display: flex;
      }

      input.kr-input-field {
        line-height: inherit;
        font-style: normal;
        font-weight: 400;

        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
        text-rendering: auto;
      }
    }

    input {
      height: auto !important;
      font-family: Georgia, serif;
      font-size: 1.4rem !important;
    }

    .kr-security-code {
      .kr-help-button {
        display: flex !important;
      }

      .kr-field-wrapper-securityCode {
        .kr-visibility-button {
          @apply mr-2 h-full;
        }
      }
    }

    .kr-card-holder-name {
      @apply mb-4;
    }

    .kr-payment-button-wrapper {
      @apply inline-flex w-full justify-end !important;
      @apply mt-12;
    }

    .kr-payment-button {
      width: auto !important;
      @apply text-white;
      @apply bg-red !important;
      border-radius: 8px !important;
      padding: 0 2rem !important;
      text-transform: uppercase !important;
      @apply font-allumi !important;
      font-size: 10px !important;
      line-height: 1 !important;
      min-height: 36px !important;
      height: auto !important;
      letter-spacing: 0.83px !important;
      text-align: center !important;
      min-width: 95px !important;
      margin-top: 0.5rem;
      z-index: 10;

      @screen large-screen {
        font-size: 1.2rem !important;
      }

      span {
        font-family: inherit !important;
        font-size: inherit;
        line-height: 1 !important;
      }

      &:hover {
        @apply bg-dark-red !important;
      }
    }

    .kr-form-error {
      width: 100% !important;

      span {
        @apply font-allumi text-red;
        font-size: 1.2rem;
      }
    }

    .kr-field-wrapper .kr-help-button-wrapper {
      display: block;
    }

    .kr-field-wrapper .kr-help-button-wrapper {
      .kr-help-button {
        width: 30px;
        height: 30px;
        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: -4px;
        margin-right: -4px;

        .kr-help-icon-wrapper {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          box-shadow: 0px 0px 0px 1px #717171;
          cursor: pointer;

          .kr-help-icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            svg {
              width: 7px;
              height: 7px;

              path {
                fill: #717171;
              }
            }
          }
        }
      }
    }

    .kr-do-register {
      font-size: 1.2rem;
      margin-bottom: -6.5rem;
      @apply mt-12;
      @apply font-allumi;
    }

    .kr-field.kr-checkbox.kr-checkbox-type-switch .kr-checkbox-input-wrapper .kr-field-control.kr-checkbox {
      input.kr-theme {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .kr-checkbox-control-indicator {
        position: relative;
        cursor: pointer;
        width: 32px;
        height: 19px;
        background-color: #ddd;
        transition: 0.3s;
        border-radius: 34px;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 2px;
        margin-right: 5px;
        border: none;
        outline: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-color: transparent;

        @screen desktop {
          width: 24px;
          height: 14px;
          border-radius: 26px;
        }

        @screen large-screen {
          width: 32px;
          height: 19px;
          border-radius: 34px;
        }
      }
      .kr-checkbox-control-indicator:focus {
        border: none;
        outline: none;
        border-color: transparent;
      }
      .kr-checkbox-control-indicator:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 2px;
        bottom: 2px;
        background-color: #fff;
        transition: 0.3s;
        border-radius: 50%;

        @screen desktop {
          height: 10px;
          width: 10px;
        }

        @screen large-screen {
          height: 15px;
          width: 15px;
        }
      }
      input:checked + .kr-checkbox-control-indicator {
        background-color: #c93a33;
      }
      input:checked + .kr-checkbox-control-indicator:before {
        transform: translateX(13px);

        @screen desktop {
          transform: translateX(10px);
        }

        @screen large-screen {
          transform: translateX(13px);
        }
      }
    }

    .kr-field.kr-checkbox {
      .kr-label {
        @apply text-grey-dark;
      }
    }
  }

  .paypal-zone {
    margin: 5.5rem 0 5rem;
    padding-top: 1rem;
    --tw-border-opacity: 1;
    border-color: rgba(229, 229, 229, var(--tw-border-opacity));
    border-top-width: 1px;

    &__content {
      @apply flex flex-col items-baseline justify-between;

      span {
        font-size: 1.2rem;
        line-height: 1.9rem;
        margin-bottom: -3rem;
        @apply inline-flex items-end;
        @apply font-allumi text-grey-dark;
      }

      .kr-smart-button-wrapper {
        @apply !flex flex-col;
        @apply w-full;

        margin: 0;

        .kr-smart-button:not(.kr-form-error) {
          @apply max-w-max;
          @apply text-white;
          @apply ml-auto;
          @apply bg-red !important;
          border-radius: 8px !important;
          padding: 0 2rem !important;
          text-transform: uppercase !important;
          min-height: 36px !important;
          height: auto !important;
          letter-spacing: 0.83px !important;
          text-align: center !important;
          min-width: 95px !important;
          border: none !important;

          &:hover {
            @apply bg-dark-red !important;
          }

          .kr-method-info {
            .kr-label {
              @apply inline-flex;
              @apply mx-auto;

              label {
                @apply font-allumi !important;
                font-size: 1rem !important;
                line-height: 1 !important;

                @screen large-screen {
                  font-size: 1.2rem !important;
                }

                &.kr-amount-label {
                  display: none;
                }
              }
            }
          }
        }

        .kr-form-error {
          @apply w-auto;
          @apply inline-block;

          span {
            @apply font-allumi text-red;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.kr-layer-manager {
  @apply font-ysans-demi;

  .kr-help-button-wrapper {
    .kr-help-modal-wrapper .kr-help-modal {
      @apply bg-white;
      border-radius: 5px;

      .kr-help-modal-header {
        @apply bg-grey-field;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: 1px solid gray;
      }

      .kr-help-modal-content {
        @apply text-grey-dark;

        font-size: 14px;
        margin: 10px 0;

        .kr-help-image {
          margin-top: 2.5rem;
        }
      }
    }
  }

  .kr-simple-modal-wrapper {
    .kr-simple-modal {
      @apply font-allumi;

      &-header {
        padding: 10px;

        &-title {
          margin: 0;
          font-size: 17px !important;
          font-style: normal;
          font-weight: bold;
        }
      }

      &-content {
        &-main {
          p {
            @apply text-black;

            font-size: 14px;
            font-style: normal;
            line-height: 20px;
            padding: 6px 10px;
          }
        }
      }

      &-footer {
        align-items: flex-end !important;

        &--vertical {
          button {
            width: fit-content;
          }
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          @apply text-white;
          @apply bg-red;
          border-radius: 8px;
          padding: 0 2rem;
          text-transform: uppercase;
          @apply font-allumi;
          font-size: 1.2rem;
          line-height: 1;
          min-height: 36px;
          letter-spacing: 0.83px;
          text-align: center;

          &:hover {
            @apply bg-dark-red;
          }

          &.kr-btn-secondary {
            @apply p-0;
            background-color: transparent;

            span {
              @apply text-black;
            }

            &:hover {
              background-color: transparent;

              span {
                @apply text-dark-red;
              }
            }
          }
        }
      }
    }
  }
}

.kr-smart-form-modal-wrapper {
  padding: 0 !important;
  justify-content: center !important;

  .kr-smart-form-modal {
    @apply w-full;
    max-width: 400px;
    @apply !pb-8;
    padding-top: 3.3rem !important;

    .kr-popin-modal-header {
      height: auto !important;

      &,
      & .kr-popin-modal-header-background {
        background-color: transparent !important;
      }

      .kr-popin-shop-name,
      .kr-popin-modal-header-image,
      .kr-popin-order-id,
      .kr-popin-amount {
        display: none;
      }

      .fi-x-circle.icon-close-popup {
        height: 1.8rem !important;
        width: 1.8rem !important;
        top: 1rem !important;
        right: 1rem !important;
        margin: 0 !important;
      }
    }

    .kr-smart-form-modal-views-container {
      @apply w-full;
      @apply px-8;

      transition: none;

      button.kr-payment-button {
        width: fit-content !important;
        margin-left: auto;
        font-size: 1.2rem !important;
      }
    }

    .kr-popin-modal-footer {
      display: none;
    }
  }
}
