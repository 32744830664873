.typeface-family-case-studies-index {
  &.container {
    @apply mx-auto tablet:mx-7 desktop:mx-auto;
  }

  .typeface-family-case-study-subnavigation {
    .subnavigation-container {
      .subnavigation-variations {
        @screen tablet {
          width: 100%;
        }

        @screen desktop {
          width: 50%;
        }
      }
    }
  }

  .grid {
    margin-left: 2.5rem;
    margin-right: 2.5rem;

    @screen tablet {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .grid-parameters {
    @apply mb-8 inline-flex w-full justify-between;
    margin-top: 3.5rem;

    @screen tablet {
      margin-top: 7.5rem;
    }

    select {
      @apply mr-0;
      @apply hidden tablet:block;
    }
  }
}
