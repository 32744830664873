.dashboards-library {
  a.button {
    @apply px-6;
  }

  &__header {
    @apply flex flex-row justify-between;
    @apply -mb-16 desktop:-mb-40;

    .left-side {
      @apply mb-14 desktop:mb-40;
    }
  }

  & &__block {
    margin-bottom: 3rem;

    @screen desktop {
      margin-bottom: 5rem;
    }

    & > header {
      margin-bottom: 5rem;
      @apply flex flex-wrap justify-between;

      .heading {
        font-size: 2.2rem;
        line-height: 2.4rem;
        @apply font-mencken-head;

        @screen desktop {
          font-size: 3.2rem;
          line-height: 3.2rem;
        }
      }

      .sort-select {
        @apply hidden desktop:block;
        @apply self-end;
      }

      .helper {
        margin-top: 1rem;
        font-size: 1.4rem;
        line-height: 2.5rem;
        @apply text-grey-dark;

        a {
          display: block;
        }

        @screen desktop {
          font-size: 1.6rem;

          a {
            display: inline;
            margin-left: 0.5rem;
          }
        }
      }
    }

    .licenses {
      .license {
        margin-top: 5rem;
      }

      .license:first-of-type {
        margin-top: 0;
      }
    }

    .license {
      header {
        padding-bottom: 0.7rem;
        @apply flex items-end;
      }

      h3 {
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: 1.4rem;
        @apply text-grey-dark;
        @apply font-allumi;

        @screen desktop {
          font-size: 1.6rem;
          line-height: 1.9rem;
          letter-spacing: 0.4px;
        }
      }

      .help-button {
        margin-left: 1rem;
        @apply hidden desktop:inline-block;
      }

      .complete {
        font-size: 1rem;
        line-heigth: 2rem;
        letter-spacing: 0.83px;
        text-transform: uppercase;
        @apply font-allumi;
        @apply text-grey-dark;

        @screen desktop {
          font-size: 1.2rem;
        }
      }
    }
  }

  &__empty_list {
    @apply mb-20;

    p {
      @apply border-b border-t border-grey-light py-12 pl-8 text-grey;

      font-size: 1.4rem;

      @screen desktop {
        font-size: 1.6rem;
      }
    }
  }

  &-existing {
    .font-library-list {
      li {
        span.caption {
          &.base-price {
            display: none;
            margin-left: 0.5rem;
            @apply line-through desktop:inline;
          }

          &.price {
            margin-left: 0.5rem;
            font-size: 1.4rem;
            @apply text-brown;
          }
        }

        .col--complete {
          order: 2;

          @screen desktop {
            text-align: right;
          }
        }
      }
    }

    .licenses {
      &[data-expand-state-value="false"] {
        .font-library-list li:nth-child(n + 4) {
          display: none;
        }

        .show-all {
          p:last-of-type {
            display: none;
          }
        }
      }

      &[data-expand-state-value="true"] {
        .show-all {
          p:first-of-type {
            display: none;
          }

          .icon-dropdown-arrow {
            transform: rotate(180deg);
          }
        }
      }

      .show-all {
        @apply text-red;
        @apply font-allumi;
        flex-direction: row-reverse;
        font-size: 1.2rem;
        line-height: 2.7rem;
        cursor: pointer;
        display: flex;
        align-items: baseline;
        margin-top: 1rem;

        &:hover {
          @apply text-dark-red;
        }

        svg.icon-dropdown-arrow {
          width: 0.9rem;
          height: 0.6rem;
          margin-top: 0.8rem;
          margin-left: 0.8rem;
        }
      }
    }
  }
}
