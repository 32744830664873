.special-item {
  @apply mb-4 text-center;

  & > a {
    display: block;
  }

  &-img-container {
    max-height: 100%;
    position: relative;
    overflow: hidden;

    @screen desktop {
      max-height: 19.5rem;
    }
  }

  &-img {
    @apply bg-grey-light;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: opacity 0.2s ease-in, transform 0.2s ease-in;

    &:hover {
      opacity: 0.8;
      transform: scale3d(1.025, 1.025, 1.025) perspective(1px) translateZ(0);
    }
  }

  &-name {
    @apply text-brown;
    margin-top: 2.7rem;
    margin-bottom: 1.2rem;
  }

  &:hover {
    .special-item-name {
      @apply text-red;
    }
  }
}
