.tags {
  @apply flex flex-wrap gap-4;

  .tag {
    padding: 0 1rem;
    font-size: 1.2rem;
    line-height: 2.7rem;
    letter-spacing: 1px;
    white-space: nowrap;
    @apply flex flex-wrap;
    @apply bg-grey-field text-grey-dark;
    @apply uppercase;

    a {
      color: inherit;
    }

    &:hover {
      @apply text-brown;
    }
  }
}
