.contacts-new {
  @apply mt-8 tablet:mt-28;
  @apply mb-32;

  .contacts__content {
    .faq-blocks {
      @apply mt-20;
    }

    @screen desktop {
      display: flex;
      flex-wrap: wrap;

      .title {
        flex: 1 0 auto;
        width: 100%;
      }

      .contacts__form {
        order: 2;
        width: 66.66%;
        margin-left: auto;
      }

      .contacts__details {
        order: 1;
        width: 33.33%;
      }

      .faq-blocks {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2rem;
      }
    }
  }

  .contacts__form {
    margin-top: 3.8rem;

    @screen desktop {
      margin-top: 10rem;
      margin-bottom: 2rem;
    }

    fieldset {
      margin-bottom: 1rem;
    }

    textarea {
      min-height: 16rem;

      @screen tablet {
        min-height: 24rem;
      }
    }
  }

  .contacts__details {
    @apply desktop:pr-16;

    margin-top: 4.5rem;
    margin-bottom: 6rem;

    @screen desktop {
      margin-top: 10rem;
    }

    h2,
    p {
      @apply text-grey-dark;
      font-size: 1.4rem;
      line-height: 2.5rem;
      letter-spacing: 0.035rem;

      @screen desktop {
        font-size: 1.6rem;
        line-height: 2.7rem;
      }
    }

    p:first-of-type {
      margin-bottom: 3.1rem;

      @screen desktop {
        margin-bottom: 2.6rem;
      }

      & img {
        margin-top: 0;
      }
    }

    img {
      margin-top: 3.3rem;
      width: 70%;

      @screen tablet {
        width: 66%;
      }

      @screen desktop {
        margin-top: 5.8rem;
        width: 85%;
      }
    }
  }
}
