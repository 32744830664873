.cms-page {
  @apply mt-8 tablet:mt-20;

  & &__content {
    @screen desktop {
      margin-left: calc(33.33333% + 20px);
      width: calc(66.66667% - 40px);
    }

    h1 {
      @apply mb-6 desktop:mb-36;
      line-height: 4rem;
    }
  }
}
