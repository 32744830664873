.font-size-slider {
  @apply flex items-center;

  @screen tablet {
    width: 18.5rem;
  }

  label {
    @apply hidden;
  }

  .rangeSlider {
    @apply flex-auto;
    height: 4px;

    &__fill {
      box-shadow: none;
      @apply bg-red;
    }

    &__handle {
      width: 16px;
      height: 16px;
      background: #fff;
      top: -6px;
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 40%);
      @apply border border-grey-dark;

      &:after {
        content: none;
      }
    }
  }
}
