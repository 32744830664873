.search-page {
  @apply mx-auto tablet:mx-7 desktop:mx-auto;

  &.empty {
    @apply pb-80;
  }

  & &__content {
    & .search-page__header {
      .search-field {
        @apply flex;
        @apply w-full py-6 pl-6 pr-6 desktop:py-11;
        @apply border border-grey-light;

        &:focus-within {
          @apply border-l-4 border-grey-dark tablet:border-l-8;
          padding-left: 1.2rem;

          @screen tablet {
            padding-left: 0.8rem;
          }
        }

        input {
          @apply ml-3 w-full;
          @apply text-grey-dark;

          outline: none;

          &:focus {
            @apply text-brown;
          }
        }
      }

      h1.title {
        @apply mt-4 desktop:mt-14;

        span {
          @apply text-red;
        }
      }
    }

    & .search-page__results {
      .results {
        @apply mt-16 desktop:mt-40;

        h3.subtitle-3 {
          @apply pt-4 pb-10 desktop:pb-16;
          @apply border-t border-grey-light;
        }

        ul.list,
        .show-all {
          @apply mr-14;
        }

        &.grid-results {
          ul.list {
            @apply flex grid grid-cols-2 grid-cols-2 gap-y-10 gap-x-4;
            @apply tablet:grid-cols-3 tablet:gap-x-8;
            @apply desktop:grid-cols-6 desktop:gap-x-12;

            li {
              &:hover {
                .grid-item-name {
                  @apply text-red;
                }
              }

              .grid-item-img {
                @apply border border-grey-light;
                aspect-ratio: 349/243;
                object-fit: cover;
                object-position: center;

                @screen tablet {
                  aspect-ratio: 293/204;
                }

                @screen desktop {
                  aspect-ratio: 168/117;
                }
              }

              .grid-item-name {
                @apply font-mencken-text italic text-grey-dark;
                @apply mt-2;

                font-size: 1.2rem;
                line-height: 1.6rem;

                @screen tablet-portrait {
                  font-size: 1.6rem;
                  line-height: 2rem;
                }
              }
            }
          }
        }

        &.list-results {
          ul.list {
            li {
              font-size: 1.4rem;

              &:not(:last-child) {
                @apply mb-4;
              }
            }
          }
        }

        &.grid-results[data-expand-state-value="false"] {
          .list li:nth-child(n + 7) {
            display: none;
          }
        }

        &.list-results[data-expand-state-value="false"] {
          .list li:nth-child(n + 9) {
            display: none;
          }
        }

        &[data-expand-state-value="false"] {
          .show-all {
            p:last-of-type {
              display: none;
            }
          }
        }

        &[data-expand-state-value="true"] {
          .show-all {
            p:first-of-type {
              display: none;
            }

            .icon-dropdown-arrow {
              transform: rotate(180deg);
            }
          }
        }

        .show-all {
          @apply text-red;
          @apply font-allumi;
          flex-direction: row-reverse;
          font-size: 1.2rem;
          line-height: 2.7rem;
          cursor: pointer;
          display: flex;
          align-items: baseline;
          margin-top: 1rem;

          &:hover {
            @apply text-dark-red;
          }

          svg.icon-dropdown-arrow {
            width: 0.9rem;
            height: 0.6rem;
            margin-top: 0.8rem;
            margin-left: 0.8rem;
          }
        }
      }
    }
  }
}
