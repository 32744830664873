.articles-index {
  @apply mt-8 tablet:mt-28;

  &.container {
    @apply mx-auto tablet:mx-7 desktop:mx-auto;
  }

  .articles-header {
    margin-bottom: 3.8rem;
  }

  ul.grid {
    grid-row-gap: 5.5rem;

    @screen desktop {
      grid-row-gap: 7rem;
    }
  }
}
