.footer {
  @apply bg-brown;
  @apply text-grey;
  padding: 5rem 4rem 3rem 4rem;

  @screen tablet-portrait {
    padding: 5rem 6rem 3rem 6rem;
  }

  &.with-cart-banner {
    padding-bottom: calc(var(--cart-banner-height) + 3rem);
  }

  & .footer__group {
    @apply mx-auto;

    @screen desktop {
      display: flex;
      justify-content: space-between;
    }

    &:first-of-type {
      margin-bottom: 5rem;

      @screen desktop {
        margin-bottom: 11rem;
      }

      p {
        font-size: 1.3rem;
        line-height: 1.6rem;

        @screen tablet-portrait {
          font-size: 1.4rem;
          line-height: 1.7rem;
        }

        @screen large-screen {
          font-size: 1.6rem;
          line-height: 2.7rem;
        }
      }
    }

    &:nth-child(2) {
      margin-bottom: 4rem;
    }

    &:last-of-type {
      justify-content: flex-start;
      align-items: center;

      @screen desktop {
        justify-content: space-between;
      }
    }
  }

  & nav {
    @apply grid grid-cols-2 gap-8 tablet:grid-cols-4 tablet:gap-10;
    @apply mt-8;
    @apply w-full desktop:w-4/6;

    @screen desktop {
      margin-bottom: 0;
      margin-left: 2rem;
    }

    & div {
      &:last-of-type {
        margin-right: 0;
      }
    }

    & h1 {
      font-size: 1.3rem;
      font-weight: 600;
      @apply font-ysans-demi;
      line-height: 2.8rem;
      letter-spacing: 1.75px;
      text-transform: uppercase;

      @screen desktop {
        font-size: 1.5rem;
        line-height: 2.7rem;
        letter-spacing: 0.115rem;
      }

      & a {
        @apply text-white hover:text-grey;
      }
    }

    & ul li a {
      @apply font-mencken-text;
      font-size: 1.3rem;
      line-height: 2rem;
      letter-spacing: 0.35px;
      @apply text-grey;

      @screen desktop {
        font-size: 1.6rem;
        line-height: 2.7rem;
        letter-spacing: 0.04rem;
      }

      &:hover {
        @apply text-white;
      }
    }
  }

  & .baseline {
    font-style: italic;
    font-size: 1.1rem;
    line-height: 2.7rem;
    letter-spacing: 0.3px;
    @apply font-mencken-text;
    @apply text-grey-dark;
    @apply mb-8;
    @apply w-full desktop:w-2/6;

    @screen desktop {
      font-size: 1.6rem;
      line-height: 2.7rem;
      letter-spacing: 0.4px;
    }

    @screen desktop {
      margin-bottom: 0;
    }
  }

  & .icon-logo-footer {
    width: 14.8rem;
    height: 4.5rem;
    /* Logo is placed outside of the padding zone */
    margin-left: -4rem;
    margin-top: -2.5rem;

    @screen tablet-portrait {
      width: 19.1rem;
      height: 5.8rem;
      margin-left: -5rem;
    }

    @screen desktop {
      margin-top: 3rem;
      width: 23.4rem;
      height: 6.8rem;
      margin-left: -6rem;
    }
  }

  & .newsletter {
    @apply flex flex-col desktop:flex-row desktop:items-baseline;

    a.button {
      width: fit-content;
    }

    p {
      @apply mt-6 font-ysans-demi desktop:mt-0 desktop:ml-8;
      font-size: 1.5rem;
      letter-spacing: 0.032rem;
    }

    @screen desktop {
      margin-left: calc(33.333% + 2rem);
    }

    &:hover {
      p {
        @apply text-white;
      }
    }
  }

  & .webring {
    margin-bottom: 2rem;

    @screen desktop {
      display: flex;
      margin-bottom: 0;
      flex: 0.9 0 auto;
    }

    & a {
      display: block;
      @apply font-allumi;
      font-size: 1.1rem;
      line-height: 2.2rem;
      letter-spacing: 0.92px;
      @apply text-grey;
      text-transform: uppercase;

      @screen desktop {
        font-size: 1.3rem;
        line-height: 2.7rem;
        letter-spacing: 1.08px;
      }

      @screen tablet {
        margin-right: 20px;
      }

      &:hover {
        @apply text-white;
      }
    }
  }

  & .social {
    @apply font-allumi;
    font-size: 1.2rem;
    @apply text-grey;

    @screen desktop {
      display: inline-flex;
      justify-content: space-between;
      font-size: 1.3rem;
      line-height: 2.7rem;
    }

    & p {
      line-height: 1.7rem;
      letter-spacing: 0.108rem;
    }

    & a {
      color: inherit;

      &:hover {
        @apply text-white;
      }
    }
  }

  & .locales {
    margin-top: 3.5rem;

    @screen desktop {
      margin-top: 0;
    }

    ul {
      @apply inline-flex;
    }

    & li {
      display: inline-block;

      &:last-of-type a {
        border-left: none;
      }

      &:last-of-type a.active {
        border-left: 1px solid;
        margin-left: -1px;
      }
    }

    & a {
      @apply text-grey;
      @apply font-allumi;
      font-size: 1.3rem;
      line-height: 2.7rem;
      letter-spacing: 0.104rem;
      padding: 0.6rem 0.9rem;
      border: 1px solid;
      text-transform: uppercase;

      &:hover {
        @apply text-white;
      }

      &.active {
        @apply text-white;
      }
    }
  }
}
