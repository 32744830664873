.segment-tabs {
  @apply flex;
  @apply border border-grey-light;
  height: 6.3rem;
  max-width: 80rem;

  @screen desktop {
    height: 10.2rem;
  }

  .segment-tab {
    @apply border-r border-grey-light;

    &:last-of-type {
      @apply border-none;
    }
  }

  .segment-tab a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 20rem;
    position: relative;
    @apply text-grey-dark;

    &:hover {
      @apply text-brown;
    }

    &.is-active {
      @apply bg-grey-dark text-white;

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        margin: auto;
        width: 0;
        height: 0;
        @apply text-grey-dark;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid currentColor;
      }
    }
  }

  .segment-tab span {
    display: none;
  }

  .segment-tab svg {
    width: 100%;
    height: 1.8rem;

    @screen desktop {
      height: 2.2rem;
    }
  }
}
