.sessions-new {
  & &-main {
    @apply mt-12 grid;

    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 2fr;
    grid-column-gap: 4rem;
    grid-row-gap: 9.5rem;

    & .section {
      & .section-title {
        font-size: 13px;
        line-height: 27px;
        @apply font-ysans-demi;
        padding-top: 6px;
        @apply border-t border-grey-light;
        margin-bottom: 16px;
      }

      &.login {
        grid-area: 1 / 1 / 2 / 4;

        @screen desktop {
          grid-area: 1 / 1 / 1 / 2;
        }
      }

      &.register {
        grid-area: 2 / 1 / 3 / 4;

        @screen desktop {
          grid-area: 1 / 2 / 2 / 4;
        }

        & form {
          @screen desktop {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            & fieldset {
              flex: 1;
              width: 50%;
              margin-right: 40px;

              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
      }
    }

    & .notes {
      @apply text-grey-dark;

      @screen tablet-portrait {
        width: 100%;
      }

      & .newsletter label {
        line-height: 27px;
      }

      & .confirmation {
        line-height: 20px;
      }
    }
  }
}
