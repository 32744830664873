.cart-element-user-details {
  @apply border-t border-grey-light;
  @apply py-2;

  h2 {
    padding-top: 1rem;
  }

  .form-group {
    @apply inline-flex items-center;
  }

  label {
    display: block;
    font-size: 1.2rem;
    line-height: 2.7rem;
    margin-left: 1rem;
    @apply font-allumi;
  }

  p {
    line-height: 2.7rem;
  }

  svg.icon-dropdown-arrow {
    width: 0.8rem;
    height: 0.5rem;
    margin-top: 0.8rem;
    margin-left: 1.2rem;
    transform: rotate(180deg);
  }

  &.has-revealed {
    h2 svg {
      transform: rotate(0deg);
    }
  }
}
