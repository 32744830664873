.saved-carts-index {
  @apply mb-32;

  & &__list {
    li {
      @apply flex justify-between border-b border-grey-light py-8 desktop:py-12;

      &:first-child {
        @apply border-t;
      }

      span.caption {
        @apply text-grey-dark;
        font-style: normal;

        @screen desktop {
          font-size: 1.2rem;
        }
      }

      p:first-child {
        @apply desktop:flex;

        span:first-child {
          font-size: 1.4rem;

          @screen desktop {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }

        span:last-child {
          @apply block desktop:ml-4 desktop:inline;
        }
      }

      .right-list-content {
        @apply flex justify-between;

        p.price {
          @apply mr-10 tablet:mr-32;
        }

        p.delete {
          flex: 0 0 auto;
          align-self: flex-start;
          margin-top: -2.1rem;

          @screen desktop {
            margin-top: -3.1rem;
          }

          .icon {
            width: 2.7rem;
            height: 2.7rem;
          }

          &:hover {
            svg.icon.icon-delete {
              @apply text-grey-dark;
            }
          }
        }
      }
    }
  }

  & &__empty-list {
    p {
      @apply border-b border-t border-grey-light py-12 pl-8 text-grey;
      font-size: 1.4rem;

      @screen desktop {
        font-size: 1.6rem;
      }
    }
  }
}
