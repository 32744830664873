/* Variables */
:root {
  /* Grid */
  --grid-max-width: 1214px;
  --grid-gap: 40px;

  --container-padding: 10px;
  --navbar-height: 61px;

  --cart-banner-height: 60px;

  @screen desktop {
    --container-padding: 40px;
    --navbar-height: 67px;
  }

  /* Layers */
  --layer-navbar: 11;
  --layer-modal: 0;
  --layer-modal-visible: 50;
}
