.error-page {
  &__content {
    @screen desktop {
      margin-left: calc(33.33333% + 20px);
      width: calc(66.66667% - 40px);
    }

    h1 {
      @apply mb-6 desktop:mb-36;
    }

    p.text {
      @apply font-mencken-text;
    }
  }
}
