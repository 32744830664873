.your-order {
  .licenses-name {
    font-size: 1.2rem;
    line-height: 2.7rem;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #9b9b9b;
    @apply inline-flex items-center;
    @apply mb-6 font-allumi;

    .help-button {
      display: none;
      margin-left: 1rem;
    }

    @screen desktop {
      font-size: 1.6rem;
      line-height: 1.9rem;
      letter-spacing: 0.4px;
      margin-bottom: 0.8rem;

      .help-button {
        display: block;
      }
    }
  }

  .typeface-group {
    margin-bottom: 0;
    @apply border-b border-grey-light;
    @apply py-4;
    @apply flex items-center;

    @screen desktop {
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
    }

    .name {
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.35px;

      @screen desktop {
        font-size: 1.6rem;
        letter-spacing: 0.4px;
      }
    }

    .format {
      letter-spacing: 0.1rem;
      font-size: 1.5rem;
      @apply ml-4 font-allumi uppercase;
    }

    .extra {
      font-size: 1.2rem;
      line-height: 2rem;
      letter-spacing: 0.4px;
      color: #c2c2c2;
      order: 3;
      margin-top: 0.2rem;
      width: 100%;
      @apply font-allumi;

      @screen desktop {
        margin-left: 1rem;
        margin-top: 0;
        letter-spacing: 0.3px;
        order: initial;
        width: auto;
      }
    }

    .price-block {
      margin-left: auto;

      .product-item-discount {
        margin-right: 1.5rem;
      }

      .old-price {
        @apply font-allumi;
        @apply text-grey line-through;
      }

      .price {
        font-size: 1.4rem;
        line-height: 2rem;
        margin-left: auto;
        @apply font-allumi;

        @screen desktop {
          font-size: 1.6rem;
        }
      }
    }

    fieldset {
      @apply my-4 w-full;
      order: 4;

      input + input {
        margin-top: 1.2rem;
      }

      @screen desktop {
        margin-top: 1.2rem;
        display: inline-flex;

        input + input {
          margin-top: 0;
          margin-left: 1rem;
        }
      }
    }
  }

  .subtotal,
  .subtotal + p {
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.5px;
    @apply font-allumi;
  }

  .subtotal {
    margin-top: 6px;
    @apply text-grey-dark;
  }

  .checkout-total {
    @screen desktop {
      margin-bottom: 4rem;
    }

    dl {
      display: flex;
      flex-wrap: wrap;
      line-height: 1.4rem;
      line-height: 2rem;
      @apply font-allumi text-grey-dark;
    }

    dt {
      @apply w-4/6;
      text-align: right;
      margin-bottom: 0.5rem;
    }

    dd {
      @apply w-2/6;
      text-align: right;
    }

    .total {
      margin-top: 2rem;
      padding-top: 1.4rem;
      @apply border-t border-grey-dark;
      @apply uppercase;

      span:first-of-type {
        @apply text-black;
      }

      dd {
        font-size: 2rem;
        @apply text-brown;

        @screen desktop {
          font-size: 2.6rem;
        }
      }
    }
  }

  .end-user-agreement {
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.35px;
    margin-bottom: 1rem;
    @apply inline-flex items-center;
    @apply text-grey-dark;

    .errors-field {
      margin-top: 0.5rem;
    }

    input {
      margin-left: 1rem;
    }

    label.field-with-errors {
      @apply underline;
      text-decoration-color: #a32e28;
    }

    @screen desktop {
      font-size: 1.6rem;
      letter-spacing: 0.4px;
      margin-bottom: 3rem;
    }
  }

  .terms-conditions,
  .print-informations {
    margin-top: 1rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
    letter-spacing: 0.4px;
    margin-bottom: 1rem;
    @apply font-allumi;
    @apply text-grey-dark;

    @screen desktop {
      margin-bottom: 0;
    }
  }

  .cart-admin-conditions {
    .markdown-block {
      * {
        margin-top: 0.5rem;
      }
    }
  }
}
