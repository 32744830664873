.prefooter {
  background-color: #f3f3f3;
  padding-top: 30px;
  padding-bottom: 30px;

  @screen desktop {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  & .grid {
    grid-template-columns: 1fr;

    @screen tablet-portrait {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    @screen tablet {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @screen desktop {
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    }

    @screen large-screen {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }

  & .prefooter__item {
    @apply text-brown;
    min-height: 130px;

    &:nth-child(3) {
      @apply hidden tablet-portrait:block;
    }

    &:last-child {
      @apply hidden tablet-portrait:block desktop:hidden;
    }

    & img {
      @apply bg-grey-dark;
      width: 100%;
      transition: transform 0.2s ease-in;
    }

    & span {
      display: block;
      margin-top: 14px;
    }

    &:hover {
      @apply text-red;

      img {
        transform: scale3d(1.025, 1.025, 1.025) perspective(1px) translateZ(0);
      }
    }
  }
}
