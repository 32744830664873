.placeholder {
  width: 100%;
  height: 100%;
  @apply bg-grey;
  @apply text-grey-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  text-transform: uppercase;
  font-family: sans-serif;
  font-size: 1.5rem;
}

.transition-rotate {
  transition: transform ease-in 0.25s;
}

.transition-rotate-0 {
  transform: rotate(0);
}

.transition-rotate-90 {
  transform: rotate(90deg);
}

.transition-rotate-180 {
  transform: rotate(180deg);
}

.transition-rotate-270 {
  transform: rotate(270deg);
}
