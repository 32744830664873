.account {
  .faq-blocks {
    margin-top: 7rem;

    @screen desktop {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    }

    @screen desktop {
      grid-column-gap: 3.8rem;
    }
  }
}
