.cart-element-extras {
  header {
    margin-top: 4.6rem;
    line-height: 2.5rem;

    h2 {
      font-size: 1.5rem;
      line-height: 2rem;
      @apply font-ysans-demi;
    }

    p {
      font-size: 1.4rem;
      line-height: 2.5rem;
      letter-spacing: 0.35px;
    }
  }

  @screen desktop {
    header {
      width: 100%;
      max-width: 75%;
      line-height: 2rem;
      margin-top: 8.6rem;

      h2 {
        font-size: 1.8rem;
      }

      p {
        font-size: 1.6rem;
        letter-spacing: 0.4px;
      }
    }
  }

  .faq-blocks {
    margin-top: 7rem;

    @screen tablet-portrait {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    }

    @screen desktop {
      grid-column-gap: 3.8rem;
    }
  }
}
