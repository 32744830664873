.modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--layer-modal);

  & .modal__container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  & .modal__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.96);
    z-index: 1;
  }

  & .modal__content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    flex: 1;
    z-index: 10;
    overflow: hidden;
  }

  &.is-visible {
    display: block;
    z-index: var(--layer-modal-visible);
  }
}

body.has-modal {
  overflow: hidden;
}
