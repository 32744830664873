.payment {
  margin-bottom: 8rem;

  @screen desktop {
    margin-bottom: 16rem;
  }

  & &-actions {
    @apply text-right;

    p {
      font-size: 1.2rem;
      line-height: 1.8rem;
      @apply font-allumi;
    }

    .price {
      font-size: 2rem;
      line-height: 2rem;

      @apply mb-6;

      @screen desktop {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }
    }
  }

  & > form > header {
    font-size: 1.9rem;
    line-height: 2rem;
    margin-top: 2.7rem;
    margin-bottom: 2.4rem;
    @apply font-ysans-demi;

    @screen desktop {
      font-size: 2.8rem;
      line-height: 2rem;
      margin-top: 6rem;
      margin-bottom: 8.5rem;
    }
  }

  .cart-element-billing-info,
  .cart-element-purchase-info,
  .cart-element-licensee-info {
    @apply border-t border-grey-light;
    @apply py-2;

    h2 {
      padding-top: 1rem;
    }
  }

  .admin-conditions {
    .markdown-block {
      * {
        margin-top: 0.5rem;
      }
    }
  }
}

.payment-confirm {
  & > header {
    @screen desktop {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
    }

    a {
      font-size: 1.4rem;
      line-height: 2rem;
      @apply font-allumi;
    }
  }

  .block-payment {
    & > p {
      font-size: 1.6rem;
      line-height: 2.7rem;
      @apply font-mencken-text;

      @screen desktop {
        font-size: 2rem;
        line-height: 2.6rem;
      }
    }

    h2 {
      margin-bottom: 1.2rem;

      @screen desktop {
        font-size: 1.5rem;
        line-height: 2.7rem;
        margin-bottom: 2.4rem;
      }
    }

    .button {
      @apply text-white;
    }
  }
}

.payment {
  @apply mt-8 tablet:mt-28;
}
