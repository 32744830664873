.add-to-cart {
  @apply inline-flex flex-none flex-col items-end desktop:flex-row desktop:items-center;
  @apply w-1/2;

  &-price {
    @apply font-allumi;
    font-size: 1.4rem;
    line-height: 2.7rem;
    font-feature-settings: "lnum" 1, "case" 1;

    @screen desktop {
      font-size: 2.2rem;
    }
  }

  &-help {
    display: none;
    margin-left: 2rem;
    margin-right: 6rem;

    @screen desktop {
      display: block;
    }
  }

  &-btn {
    font-size: 1.2rem;
    letter-spacing: 0.01rem;
    line-height: 2rem;
    padding: 0 3rem;
    cursor: pointer;
  }

  @screen desktop {
    width: auto;
  }
}
