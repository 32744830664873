.typeface-segments-glyphs {
  .glyphs-header {
    @apply flex items-center justify-start;

    select {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .glyphs-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 5rem);
    grid-gap: 4px;

    @screen desktop {
      grid-template-columns: repeat(auto-fill, 9.6rem);
    }
  }

  #glyphs_count {
    margin-left: 0.5rem;
    font-size: 1rem;
    line-height: 2.7rem;
    letter-spacing: 1px;
    @apply font-allumi;
  }
}
