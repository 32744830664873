.articles-item {
  @apply list-none;
  @apply col-span-1;

  & > a,
  & > div {
    @apply grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    color: inherit;
  }

  & > a {
    @apply hover:text-dark-red;
  }

  &__title {
    @apply break-words;

    grid-column: inherit;

    padding-left: 25px;
    @screen tablet {
      padding-left: 0;
    }
  }

  &__image {
    @apply block;
    grid-column: inherit;
    margin-bottom: 2rem;

    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    width: 100vw;

    @screen tablet-portrait {
      max-width: 100%;
      width: 100%;
      position: static;
      margin-left: 0;
      margin-right: 0;

      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      transition: box-shadow 0.2s ease-in;
    }

    @screen desktop {
      margin-bottom: 4rem;
    }
  }

  &__excerpt {
    font-size: 1.4rem;
    line-height: 1.78;
    letter-spacing: 0.04rem;
    margin-bottom: 2rem;
    grid-column: inherit;

    @screen desktop {
      font-size: 1.8rem;
      line-height: 2.9rem;
      letter-spacing: 0.045rem;
      margin-bottom: 5rem;
    }

    &::first-letter {
      float: left;
      margin-right: 10px;
      margin-bottom: 5px;
      font-size: 87px;
      line-height: 52px;

      @screen desktop {
        font-size: 103px;
        line-height: 64px;
      }
    }

    padding-left: 25px;

    @screen tablet {
      padding-left: 0;
    }
  }

  &__date {
    @apply block;
  }

  &__categories {
    & > span {
      display: none;
    }

    @screen tablet {
      & > span {
        display: inline-block;
      }

      margin-left: 4px;
    }
  }

  footer {
    @apply border-t border-grey-light;
    padding-top: 7px;

    margin-left: 35px;
    @screen tablet {
      margin-left: 0;
      display: inline-flex;
    }

    .articles-item__date {
      @apply flex-shrink-0;
    }
  }

  /* Variants */
  &.is-focus {
    @apply tablet:col-span-2 desktop:col-span-3;

    & > a,
    & > div {
      @screen tablet {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @screen desktop {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    .articles-item__title {
      @apply tablet:col-span-2;
      @apply tablet:col-start-2;
    }

    .articles-item__image {
      @apply tablet:col-span-3;
    }

    .articles-item__excerpt {
      @apply tablet:col-span-2;
      @apply tablet:col-start-2;
    }

    footer {
      @apply tablet:col-span-2 tablet:col-start-2;
    }
  }

  &--gazette {
    .articles-item__title {
      @apply font-mencken-compress uppercase;
      font-size: 4.3rem;
      line-height: 0.93;
      margin-bottom: 3rem;

      @screen desktop {
        margin-top: -7px; /* To align title with news one */
        font-size: 10rem;
        margin-bottom: 4.5rem;
      }
    }

    .articles-item__excerpt::first-letter {
      @apply font-ysans-demi;
    }

    @apply tablet:col-span-2;
  }

  &--news {
    .articles-item__title {
      @apply font-ysans-demi;
      font-size: 2.2rem;
      line-height: 1.09;
      margin-bottom: 2rem;

      @screen desktop {
        font-size: 5rem;
        margin-bottom: 4rem;
      }
    }

    .articles-item__excerpt::first-letter {
      @apply font-mencken-compress;
      font-size: 88px;
      line-height: 54px;

      @screen desktop {
        font-size: 108px;
        line-height: 65px;
      }
    }
  }

  /* States */
  &:hover {
    .articles-item__image {
      @screen tablet-portrait {
        box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.6);
      }
    }
  }
}

@-moz-document url-prefix() {
  .articles-item {
    .articles-item__excerpt {
      &::first-letter {
        margin-top: 7px;

        @screen desktop {
          font-size: 104px !important;
        }
      }
    }

    &--news {
      .articles-item__excerpt {
        &::first-letter {
          margin-top: 8px;

          @screen desktop {
            font-size: 105px !important;
          }
        }
      }
    }
  }
}
