.typeface-segments-index {
  &.container {
    @apply mx-auto tablet:mx-7 desktop:mx-auto;
  }

  .grid-parameters {
    @apply hidden tablet:inline-flex;
    @apply w-full;
    margin-top: 7.5rem;
    margin-bottom: 1.6rem;

    select:nth-child(2) {
      @apply mr-0;
    }
  }

  .grid {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;

    @screen tablet {
      margin-left: auto;
      margin-right: auto;
    }

    @screen tablet {
      margin-top: 0;
    }
  }
}
