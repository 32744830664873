.help-box-block {
  @apply bg-grey-field;
  padding: 1.2rem 1rem 1.5rem 1.2rem;
  @apply text-grey-dark;
  @apply border-t border-grey-light;
  margin-bottom: 1rem;

  @screen tablet {
    margin-bottom: 2rem;
  }

  @screen desktop {
    margin-bottom: 4rem;
    padding: 1.6rem 1.9rem 3.9rem 1.9rem;
  }

  &__title {
    @apply text-brown;
    font-size: 1.3rem;
    @apply font-ysans-demi;
    margin-bottom: 1rem;
  }

  &__content {
    font-size: 1.2rem;
    @apply font-allumi;
    line-height: 2rem;

    p {
      @apply inline-block;

      & + p {
        @apply mt-2;
      }
    }
  }

  a {
    margin-left: 0.4rem;
  }
}
