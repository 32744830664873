/* Allumi */
@font-face {
  font-family: "AllumiSpe";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/AllumiSpe-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "AllumiSpe";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/AllumiSpe-Italic.woff2") format("woff2");
}

/* Mencken Head */
@font-face {
  font-family: "MenckenSpeHead";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/MenckenSpeHead-Bold.woff2") format("woff2");
}

/* Mencken Head Compressed */
@font-face {
  font-family: "MenckenSpeHeadCompress";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/MenckenSpeHeadCompress-Bold.woff2") format("woff2");
}

/* Mencken Text */
@font-face {
  font-family: "MenckenSpeText";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/MenckenSpeText-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "MenckenSpeText";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/MenckenSpeText-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "MenckenSpeText";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/MenckenSpeText-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "MenckenSpeText";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/MenckenSpeText-BoldItalic.woff2") format("woff2");
}

/* Ysans */
@font-face {
  font-family: "YsansSpeDemi";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/YsansSpe-Demi.woff2") format("woff2");
}

/* Adobe Not Def */
@font-face {
  font-family: "Adobe NotDef";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/AND-Regular.otf") format("opentype");
}
