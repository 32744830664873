.about-page {
  &.container {
    @apply mx-auto tablet:mx-7 desktop:mx-auto;
  }

  & &__content {
    @screen desktop {
      margin-left: calc(33.33333% + 20px);
      width: calc(66.66667% - 40px);
    }

    h1 {
      @apply mb-6 desktop:mb-36;
      line-height: 4rem;
    }

    h3.subtitle-3 {
      @apply pt-4 pb-10 desktop:pb-16;
      @apply border-t border-grey-light;

      a {
        color: inherit;

        &:hover {
          @apply text-dark-red;
        }
      }
    }

    .markdown {
      p {
        padding-left: 2rem;
        padding-right: 2rem;

        @screen desktop {
          padding: 0;
        }
      }
    }

    .designers-list {
      @apply mb-36 grid grid-cols-2 gap-8 tablet:grid-cols-4 desktop:mb-72 desktop:gap-x-16;
    }
  }
}
