.default-index {
  .carousel {
    min-height: auto;
  }

  .container {
    ul.pushes-list,
    ul.specials-list {
      @apply grid grid-cols-1 gap-y-14 gap-x-14 py-16;
      @apply tablet-portrait:grid-cols-2;
    }

    ul.pushes-list {
      @apply desktop:grid-cols-3;
    }
  }
}
