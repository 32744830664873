.push-item {
  & > a {
    display: block;
  }

  &-img-container {
    max-height: 100%;
    position: relative;
    overflow: hidden;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s ease-in;
    @apply border border-grey-light;

    @screen desktop {
      max-height: 26.4rem;
    }
  }

  &-img {
    @apply bg-grey-light;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &-name {
    @apply text-brown;
    margin-top: 2rem;

    svg {
      float: left;
      margin-top: 0.5rem;

      @screen desktop {
        margin-top: 1rem;
      }
    }
  }

  &-caption {
    @apply hidden tablet:block;
    @apply italic text-grey-dark;
    font-size: 1.6rem;
    line-height: 2.7rem;
    letter-spacing: 0.04rem;
  }

  &:hover {
    .push-item-name,
    .push-item-caption {
      @apply text-red;
    }

    .push-item-img-container {
      box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.6);
    }
  }
}
