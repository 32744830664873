.payment,
.checkout,
.account-carts-show {
  .cart-element-payment,
  .cart-element-user-details {
    @apply mb-16;
  }

  @screen desktop {
    .your-order {
      width: 100%;
      @apply col-span-2;

      .checkout-total {
        max-width: 50%;
        margin-left: auto;
        margin-top: 4rem;
      }
    }

    .cart-element-billing-info,
    .cart-element-purchase-info {
      @apply mb-16;
    }
  }

  .cart-element-billing-info,
  .cart-element-purchase-info,
  .cart-element-licensee-info {
    @apply pb-2;

    p {
      line-height: 2.7rem;
    }

    svg.icon-dropdown-arrow {
      width: 0.8rem;
      height: 0.5rem;
      margin-top: 0.8rem;
      margin-left: 1.2rem;
      transform: rotate(180deg);
    }

    textarea {
      height: 10rem;
    }

    &.has-revealed {
      h2 svg {
        transform: rotate(0deg);
      }
    }
  }
}
