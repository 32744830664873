.pagination {
  @apply flex flex-col;
  @apply mt-12 tablet:mt-16 desktop:mt-24;
  margin-left: 2.5rem;
  @apply tablet:ml-0;

  @apply font-allumi;
  font-size: 1.3rem;
  line-height: 2rem;

  @screen tablet {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }

  @screen desktop {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  &-links {
    @apply desktop:mb-6;
  }

  &-link {
    @apply flex items-baseline;
    @apply text-grey-dark hover:text-dark-red;

    .icon {
      @apply text-brown;
      @apply mr-4;
    }

    a {
      color: inherit;
    }

    &:hover {
      .icon {
        @apply text-black;
      }
    }
  }

  &-results {
    @apply inline-flex gap-x-2;
    @apply text-grey;
  }
}
