.dashboards-show,
.dashboards-library {
  .font-library-list {
    li {
      width: 100%;
      padding-top: 1.85rem;
      padding-bottom: 1.5rem;
      min-height: 8rem;

      @apply inline-flex flex-wrap items-start justify-between;
      @apply border-b border-grey-light;

      &.complete-family {
        @apply content-center;
      }

      &:first-child {
        @apply border-t border-grey-light;
      }

      span.format {
        letter-spacing: 0.1rem;
        font-size: 1.4rem;
        @apply ml-4 font-allumi uppercase;
      }

      span.caption {
        @apply text-grey-dark;
        font-style: normal;

        @screen desktop {
          font-size: 1.2rem;
        }
      }

      .invoice,
      .add-artwork-license {
        font-size: 1.2rem;
        line-height: 2rem;
        letter-spacing: 0.3px;
        @apply font-allumi;
      }

      .add-artwork-license {
        @apply mt-4 block;
      }

      .col {
        &--title {
          flex: 1 1;
          order: 1;

          p {
            line-height: 2rem;
          }

          span.caption {
            display: block;
            margin-left: 0;
          }
        }

        &--helper {
          width: 100%;
          order: 3;
          line-height: 2rem;
        }

        &--cart {
          order: 2;

          span.caption {
            @apply mr-4 hidden desktop:inline;
          }

          a.button {
            @apply inline-block;
          }

          @screen desktop {
            text-align: right;
          }
        }
      }
    }
  }
}
