.offers {
  h3.subtitle-3 {
    svg.icon-dropdown-arrow {
      width: 0.8rem;
      height: 0.5rem;
      margin-top: 0.8rem;
      margin-left: 1.2rem;
      transform: rotate(180deg);
    }
  }

  &.has-revealed {
    h3.subtitle-3 {
      svg.icon-dropdown-arrow {
        transform: rotate(0deg);
      }
    }
  }

  &-container {
    @apply flex;
    @apply flex-wrap tablet:flex-nowrap;

    @screen tablet {
      display: flex !important;
    }
  }

  .offer {
    @apply border border-grey-light;
    padding: 0 1.4rem;
    height: 9rem;
    position: relative;
    @apply w-full;

    @screen tablet {
      max-width: 33.33%;
      flex: 0 1 auto;
      margin-right: 3.5rem;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover .offer-tooltip {
        display: inline-table;
      }
    }

    @screen desktop {
      height: 12rem;
    }

    &-name {
      font-size: 3.4rem;
      line-height: 4.7rem;
      margin-right: 1rem;

      @screen desktop {
        font-size: 5rem;
        line-height: 7rem;
      }
    }

    &-count {
      font-size: 1.4rem;
      @apply text-grey-dark;
      @apply font-allumi;

      @screen desktop {
        font-size: 1.6rem;
      }
    }

    &-add {
      position: absolute;
      /* we use -1px because of the border */
      top: -1px;
      right: -1px;
    }

    &-tooltip {
      @apply hidden;
      @apply border-l border-grey-light;
      @apply z-10;
      position: absolute;
      top: 100%;
      width: calc(100% - 4px);
      left: 4px;
      background: rgba(255, 255, 255, 0.96);
      height: auto;
      margin-top: 1px;
      padding: 0.8rem 1rem;
      font-size: 1.2rem;
      line-height: 2rem;
      @apply text-grey-dark;
      @apply font-allumi;
    }
  }
}
