.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  @apply bg-brown;
  @apply text-white;
  z-index: var(--layer-navbar);
  height: var(--navbar-height);

  & .navbar-menu {
    position: static;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: height 0.125s ease;
  }

  & .navbar-menu > a {
    display: flex;
    @apply text-grey;
    letter-spacing: 0.58px;
    font-size: 1.4rem;
    line-height: var(--navbar-height);
    text-transform: uppercase;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    flex: 0;
    border-bottom: 4px solid transparent;
    margin-top: 0.5rem;

    @screen desktop {
      align-items: flex-end;
      height: 100%;
      margin-top: 0;
      padding-bottom: 2rem;
    }

    &.navbar-menu__fonts svg {
      width: 3.7rem;
      height: 1.8rem;

      @screen desktop {
        width: 4.9rem;
        height: 1.7rem;
      }
    }

    &.navbar-menu__store svg {
      width: 3.7rem;
      height: 1.8rem;

      @screen desktop {
        width: 4.9rem;
        height: 1.7rem;
      }
    }

    &.navbar-menu__articles svg {
      width: 5.2rem;
      height: 1.8rem;

      @screen desktop {
        width: 6.9rem;
        height: 1.7rem;
      }
    }

    &.navbar-menu__help svg {
      width: 3.1rem;
      height: 1.8rem;

      @screen desktop {
        width: 4.1rem;
        height: 1.7rem;
      }
    }

    &.navbar-menu__about svg {
      width: 4.3rem;
      height: 1.8rem;

      @screen desktop {
        width: 5.3rem;
        height: 1.8rem;
      }
    }

    &.navbar-menu__fonts-in-use svg {
      width: 4.6rem;
      height: 1.7rem;

      @screen desktop {
        width: 5.6rem;
        height: 1.8rem;
      }
    }

    &:hover {
      @apply text-white;
      @apply border-red;
    }

    &.is-active {
      @apply text-grey;
      @apply border-red;
    }
  }

  & .navbar-menu .navbar-menu__logo {
    display: block;
    height: auto;
    border-bottom: 0;
    padding-bottom: 0;
    margin-left: 0;
    margin-top: 0;

    &:hover {
      opacity: 0.75;
    }

    @screen desktop {
      margin-right: 3rem;
      padding-top: 0;
      margin-left: 0.8rem;
    }

    @screen large-screen {
      position: absolute;
      left: 3.5rem;
      top: 1.6rem;
    }

    & svg {
      width: 4.1rem;
      height: 2.8rem;

      @screen desktop {
        width: 5.2rem;
        height: 3.4rem;
      }
    }
  }

  & .navbar-user-menu__search {
    & svg {
      @apply float-right;

      width: 1.4rem;
      height: 1.4rem;

      @screen desktop {
        width: 1.7rem;
        height: 1.7rem;
      }
    }
  }

  & .navbar-user-menu {
    @apply ml-auto mb-8 mr-2 flex tablet-portrait:mr-0;
    @apply flex-col gap-y-4 tablet-portrait:flex-row;

    margin-top: 1.4rem;

    @screen desktop {
      margin-top: 0.8rem;
    }

    a {
      @apply text-grey;

      &:hover {
        @apply text-white;
      }
    }
  }

  & .navbar-user-menu__login {
    font-size: 11px;
    line-height: 14px;
    text-underline-offset: 2px;
    @apply absolute font-allumi;
    @apply tablet-portrait:mr-8;
    position: inherit;

    @screen desktop {
      position: inherit;

      font-size: 1.3rem;
      line-height: 1.7rem;
    }
  }

  /* Overrides */
  & .navbar-user-menu {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}
