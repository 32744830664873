.checkout {
  .cart-button {
    min-height: 2.7rem;

    @screen desktop {
      font-size: 1.2rem;
      line-height: 2rem;
      padding: 0 2rem;
    }
  }

  & &-actions {
    @apply text-right;

    p {
      font-size: 1.2rem;
      line-height: 1.8rem;
      @apply font-allumi;
    }

    .price {
      font-size: 2rem;
      line-height: 2rem;

      @apply mb-6;

      @screen desktop {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }
    }

    input {
      min-height: 2.7rem;
    }
  }

  .quote-print {
    font-size: 1.2rem;
    line-height: 1.8rem;
    @apply font-allumi;
    @apply hidden desktop:block;
  }
}
