.designer-item {
  &-name {
    @apply mt-2 text-grey-dark;

    font-size: 1.2rem;

    @screen tablet {
      font-size: 1.4rem;
    }

    @screen desktop {
      font-size: 1.6rem;
    }
  }

  &:hover {
    .designer-item-name {
      @apply text-dark-red;
    }
  }
}
