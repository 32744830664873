.font-overview-header {
  height: 6rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-sizing: content-box;
  @apply sticky;
  @apply mb-8 tablet:mb-24;
  @apply border-b border-grey-light;
  top: 61px;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 1;

  &.has-discount {
    height: 8rem;

    @screen desktop {
      height: 6rem;
    }

    .font-licenses {
      justify-content: center;

      @screen tablet {
        justify-content: flex-start;
      }
    }

    .add-to-cart {
      justify-content: center;
    }
  }

  @screen tablet {
    top: 66px;
  }

  .container {
    @apply relative flex flex-row justify-between;
    @apply h-full;
    @apply mx-auto;
  }
}
