.subnavigation {
  &-container {
    @apply border border-grey-light;
    @apply flex w-full flex-wrap;
    min-height: 8.2rem;

    @screen tablet {
      /* height: 15rem; */
    }
  }

  &-variations,
  &-caracteristics {
    display: inline-flex;
    align-items: stretch;

    label {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-self: center;

      &:hover {
        @apply text-brown;
      }
    }

    input {
      display: none;
    }

    input:checked + label {
      @apply bg-grey-dark text-white;
    }
  }

  &-reset,
  &-variations label {
    /* padding-left: 1.4rem;
    padding-right: 1.4rem; */
    @apply border-r border-grey-light;

    a {
      /* TODO: cleanup */
      @apply text-grey-dark;

      &:hover {
        @apply text-brown;
      }
    }

    @screen tablet {
      /* padding-left: 2.4rem;
      padding-right: 2.4rem; */
    }
  }

  &-reset {
    flex: 1 1 auto;
    padding: 1.5rem 1rem;
    @apply text-grey-dark;

    button {
      height: 100%;
    }

    span {
      display: none;
    }

    svg {
      width: 6.5rem;
      height: 2.2rem;
    }

    @screen desktop {
      width: 12rem;
      padding: 3rem;
    }

    &--clear {
      @apply desktop:px-6 large-screen:px-12;

      a {
        margin: auto;

        svg {
          width: 5rem;

          @screen desktop {
            width: 6rem;
          }
        }
      }
    }

    &--all {
      @apply bg-grey-dark;

      svg {
        width: 4rem !important;
        margin: auto;
        padding: 0.7rem 0;
      }
    }

    &--all,
    &--clear {
      @apply flex;
      width: 3.3rem;

      @screen desktop {
        width: 6.8rem;
      }
    }
  }

  &-variations {
    width: 100%;
    min-height: 4.6rem;
    align-self: stretch;

    label {
      flex: 1 1 auto;
      justify-content: center;
      @apply text-grey-dark;

      &:last-of-type {
        border-right: none;
      }
    }

    svg {
      width: 3.2rem;
      height: 3.3rem;

      @screen desktop {
        width: 6.7rem;
        height: 4.2rem;
      }
    }

    @screen tablet {
      width: calc(100% - 21rem);
      order: 1;
    }

    @screen desktop {
      width: 50%;
    }
  }

  &-search {
    display: inline-flex;
    align-items: center;
    flex: 1 1 auto;
    padding: 0 2rem;
    min-height: 4.6rem;
    @apply border-t border-grey-light desktop:border-t-0 desktop:border-l;

    &:focus-within {
      @apply border-l-4 border-l-grey-dark tablet:border-l-8;
      padding-left: 1.6rem;

      @screen tablet {
        padding-left: 1.3rem;
      }
    }

    input {
      width: 100%;
      line-height: 2rem;
      padding: 0.5rem;

      outline: none;
    }

    @screen tablet {
      width: 100%;
      order: 4;
    }

    @screen desktop {
      width: auto;
      order: 2;
    }
  }

  &-license {
    padding: 1.5rem;
    @apply hidden tablet:flex;
    @apply border-l border-grey-light;
    @apply self-stretch;
    @apply items-center;

    select {
      width: 100%;
      align-self: flex-start;
    }

    @screen tablet {
      order: 2;
    }

    @screen desktop {
      width: 100%;
      max-width: 18rem;
    }
  }

  /* Secondary */
  &-caracteristics {
    @apply hidden tablet:block;
    @apply border-grey-light tablet:border-t;

    label {
      @apply text-grey;
      width: 7.1rem;
      height: 5rem;
    }

    div {
      display: inline-flex;
      align-items: center;
    }

    svg {
      margin: auto;

      &.icon-filter_thickness {
        width: 2.78rem;
        height: 1.69rem;
      }

      &.icon-filter_black {
        width: 4.64rem;
        height: 1.73rem;
      }

      &.icon-filter_narrow {
        width: 2.54rem;
        height: 1.76rem;
      }

      &.icon-filter_extended {
        width: 4.41rem;
        height: 1.73rem;
      }

      &.icon-filter_title {
        width: 3.37rem;
        height: 1.96rem;
      }

      &.icon-filter_caps {
        width: 4.67rem;
        height: 1.64rem;
      }
    }

    @screen tablet {
      order: 3;
      width: 100%;
    }

    @screen desktop {
      width: 50%;
    }
  }

  &-weight,
  &-space,
  &-title,
  &-capitals {
    display: inline-flex;
    align-items: stretch;
    height: 100%;
  }

  &-weight label,
  &-space label,
  &-title,
  &-capitals {
    @apply border-r border-grey-light;
  }

  &-tags {
    min-height: 51px;
    @apply hidden tablet:flex;
    @apply items-center;
    @apply border-t border-l border-grey-light;
    margin-left: auto;

    @screen tablet {
      order: 5;
      width: 100%;
    }

    @screen desktop {
      width: 50%;
    }

    .tags {
      overflow: hidden;
      padding: 1rem 1.5rem;
      display: block;
      width: 100%;
      max-height: 4.6rem;

      .tag {
        display: inline-block;
        margin-bottom: 1rem;
        padding: 0;
        @apply mr-2;

        span {
          display: block;
          padding: 0 1rem;
        }

        input:checked + span {
          @apply bg-grey-dark text-grey-field;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &-more {
    @apply hidden tablet:block;
    position: relative;
    text-align: right;
    margin: 0.5rem 0;

    button {
      @apply font-allumi text-grey-dark hover:text-brown;
      @apply inline-flex items-center;
      font-size: 1.2rem;
      line-height: 2rem;
      letter-spacing: 0.3px;
      cursor: pointer;

      &:last-of-type {
        display: none;
      }
    }

    svg {
      margin-left: 1rem;
      width: 0.8rem;
      height: 0.5rem;
    }
  }

  /* States */
  &.has-tags-open {
    .subnavigation-caracteristics > div {
      @apply border-b border-grey-light;
    }

    .tags {
      max-height: 18.4rem;
      overflow-y: scroll;
    }

    .subnavigation-more {
      button:first-of-type {
        display: none;
      }

      button:last-of-type {
        display: inline-flex;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
