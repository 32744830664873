.help-header {
  min-height: 9.2rem;
  @apply w-full;

  &__container {
    @apply w-full;
    @apply inline-flex flex-wrap;
    @apply border border-grey-light;
  }

  &__search,
  &__kind {
    flex: 1 1 auto;

    @screen desktop {
      height: 10.2rem;
    }
  }

  &__kind {
    padding: 1.5rem 1rem;
    position: relative;
    height: 6.3rem;
    @apply w-1/2;

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    span {
      display: none;
    }

    svg {
      @apply m-auto;
      @apply text-grey-dark;

      height: 3.3rem;
      width: 6rem;
      padding: 0.7rem 0;
    }

    @screen tablet {
      svg {
        padding: 0;
      }
    }

    &--help {
      @screen tablet {
        max-width: 12rem;

        svg {
          width: 5.2rem;
          height: 4rem;
        }
      }
    }

    @screen tablet {
      height: auto;
    }

    &--licenses {
      @screen tablet {
        max-width: 14.5rem;

        svg {
          width: 8.4rem;
          height: 4rem;
        }
      }
    }

    &:not(&--active) {
      a svg:hover {
        @apply text-brown;
      }
    }

    &--active {
      @apply bg-grey-dark;

      svg {
        @apply text-white;
      }

      &:after {
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        margin: auto;
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #737373;
      }
    }
  }

  &__search {
    @apply w-full;
    display: inline-flex;
    align-items: center;
    flex: 1 1 auto;
    padding: 0 2rem;
    min-height: 4.6rem;
    @apply border-t border-grey-light tablet:border-t-0 tablet:border-l;

    &:focus-within {
      @apply border-l-4 border-l-grey-dark tablet:border-l-8;
      padding-left: 1.6rem;

      @screen tablet {
        padding-left: 1.3rem;
      }
    }

    input {
      width: 100%;
      line-height: 2rem;
      padding: 0.5rem;

      outline: none;
    }

    @screen tablet {
      flex: 1;
    }
  }

  &__tags {
    min-height: 51px;
    @apply hidden tablet:flex;
    @apply items-center;
    @apply border-t border-l border-grey-light;
    margin-left: auto;

    @screen tablet {
      width: 100%;
    }

    .tags {
      overflow: hidden;
      padding: 1rem 1.5rem;
      display: block;
      width: 100%;
      max-height: 4.6rem;

      .tag {
        display: inline-block;
        margin-bottom: 1rem;
        padding: 0;
        @apply mr-2;

        span {
          display: block;
          padding: 0 1rem;
        }

        input:checked + span {
          @apply bg-grey-dark text-grey-field;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__actions {
    @apply w-full;

    &__more {
      @apply hidden tablet:block;
      position: relative;
      text-align: right;
      margin: 0.5rem 0;

      button {
        @apply font-allumi text-grey-dark hover:text-brown;
        @apply inline-flex items-center;
        font-size: 1.2rem;
        line-height: 2rem;
        letter-spacing: 0.3px;
        cursor: pointer;

        &:last-of-type {
          display: none;
        }
      }

      svg {
        margin-left: 1rem;
        width: 0.8rem;
        height: 0.5rem;
      }
    }
  }

  /* States */
  &.has-tags-open {
    .tags {
      max-height: 18.4rem;
      overflow-y: scroll;
    }

    .help-header__actions__more {
      button:first-of-type {
        display: none;
      }

      button:last-of-type {
        display: inline-flex;
      }

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
