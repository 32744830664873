.about-designers-index {
  margin-bottom: 2rem;

  header {
    @apply flex flex-col place-items-start justify-between tablet:flex-row;
    margin-bottom: 2.5rem;

    h1.main-title-alt {
      @apply mt-4 tablet:mt-0 tablet:ml-16 desktop:-mt-2 desktop:ml-4;
    }

    span {
      @apply inline-flex flex-col tablet:flex-row;
    }

    .sort-select {
      @apply hidden tablet:mt-24 tablet:inline-block desktop:mt-52;

      select {
        width: 17rem;
        margin-right: 0;
      }
    }
  }

  .grid-parameters {
    @apply hidden tablet:inline-flex;
    @apply w-full;
    margin-top: 9.5rem;
    margin-bottom: 1.6rem;
  }

  ul.grid {
    @apply grid-cols-1 tablet-portrait:grid-cols-2 desktop:grid-cols-3 desktop:gap-y-36;

    margin-top: 3.5rem;
    margin-bottom: 3.5rem;

    @screen desktop {
      margin-top: 0;
    }
  }

  &-join-us-link {
    @apply mt-2 font-allumi tablet:ml-6 tablet:mt-0 desktop:mt-11;
    font-size: 1.2rem;
    line-height: 2rem;
    letter-spacing: 0.5px;
  }

  &-about-link {
    @apply tablet:mt-3 desktop:mt-11;
  }

  #external-designers {
    @apply desktop:mt-36;

    p.designers {
      @apply border-t border-grey-light;
      @apply pt-2 desktop:mb-16;

      padding-left: 2.25rem;

      @screen desktop {
        padding-left: 0;
      }
    }
  }
}
