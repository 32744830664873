.text-sample {
  @apply mr-4;
  @apply font-allumi;
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 1px;
  text-transform: none;

  @screen desktop {
    max-width: 25.5rem;
  }
}
