.tippy-box[data-theme="tf"] {
  @apply bg-brown;
}

.tippy-box[data-theme~="tf"][data-placement^="left"] > .tippy-arrow::before {
  @apply bg-brown;
}
.tippy-box[data-theme~="tf"][data-placement^="right"] > .tippy-arrow::before {
  @apply border-r-brown;
}
.tippy-box[data-theme~="tf"][data-placement^="top"] > .tippy-arrow::before {
  @apply border-t-brown;
}
.tippy-box[data-theme~="tf"][data-placement^="bottom"] > .tippy-arrow::before {
  @apply border-b-brown;
}

.tippy-box[data-theme="tf"] .tippy-content,
.tippy-box[data-theme="tf_error"] .tippy-content {
  @apply font-allumi;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
  padding: 15px 12px;
  text-align: center;
  text-transform: none;

  a {
    @apply text-grey-light;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-color: #c93a33;
    transition: text-decoration-color ease-in 0.2s, color ease-in 0.2s;

    &:hover {
      @apply text-grey;
      text-decoration-color: #a32e28;
    }
  }
}

.tippy-box[data-theme="tf_error"] .tippy-content {
  @apply bg-red text-left;
}

.tippy-box[data-theme~="tf_error"] > .tippy-arrow::before {
  @apply text-red;
}
