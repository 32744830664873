a {
  transition: color 0.2s ease-in;
  @apply text-red;
}

a:hover {
  @apply text-dark-red;
}

/* General  */
.text {
  @apply font-ysans-demi;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.35px;

  @screen desktop {
    font-size: 18px;
    line-height: 29px;
    letter-spacing: 0.45px;
  }
}

.title {
  @apply font-ysans-demi;
  font-size: 19px;
  line-height: 22px;

  @screen desktop {
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.5px;
  }
}

.subtitle {
  @apply font-ysans-demi;
  font-size: 15px;
  line-height: 19px;

  @screen desktop {
    font-size: 18px;
    line-height: 22px;
  }
}

.section-subtitle {
  @apply font-ysans-demi;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.2px;

  @screen desktop {
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0;
  }
}

/* UI */
.ui-text {
  @apply font-allumi;
  font-size: 14px;
  line-height: 18px;

  @screen desktop {
    font-size: 16px;
    line-height: 27px;
  }
}

.ui-small-text {
  @apply font-allumi;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.5px;
  font-feature-settings: "lnum" 1, "case" 1;

  @screen desktop {
    font-size: 12px;
    line-height: 15px;
  }
}

/* Main */
.main-title {
  @apply font-mencken-compress;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  @screen desktop {
    font-size: 100px;
    line-height: 92px;
    letter-spacing: 0;
  }
}

.main-title-alt {
  @apply font-ysans-demi;
  font-size: 22px;
  line-height: 22px;

  @screen desktop {
    font-size: 50px;
    line-height: 50px;
    letter-spacing: -0.5px;
  }
}

/* Subtitles */
.subtitle-1 {
  @apply font-mencken-head;
  font-size: 22px;
  line-height: 22px;
  padding-top: 10px;
  @apply border-t border-grey-light;

  @screen desktop {
    font-size: 32px;
    line-height: 32px;
  }
}

.subtitle-2 {
  @apply font-ysans-demi;
  font-size: 18px;
  line-height: 22px;

  @screen desktop {
    font-size: 25px;
    line-height: 30px;
  }
}

.subtitle-3 {
  @apply font-ysans-demi;
  font-size: 15px;
  line-height: 20px;

  @screen desktop {
    font-size: 18px;
  }
}

.subtitle-4 {
  @apply font-ysans-demi;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;

  @screen desktop {
    font-size: 14px;
  }
}

.caption {
  @apply font-allumi;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  font-style: italic;

  @screen desktop {
    font-size: 14px;
  }
}

/* Headlines */
.headline-a {
  @apply font-ysans-demi;
  font-feature-settings: "onum" 1, "pnum" 1;
  font-size: 22px;
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 14px;
}

.headline-b {
  @apply font-mencken-head;
  font-feature-settings: "pnum" 1;
  font-size: 20px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 6px;
  padding-top: 10px;
  @apply border-t border-grey-light;
}

.headline-c {
  @apply font-mencken-head;
  font-feature-settings: "pnum" 1;
  font-size: 20px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 6px;
  padding-top: 4px;
  @apply border-t border-grey-light;
}

.headline-d {
  @apply font-ysans-demi;
  font-feature-settings: "onum" 1, "pnum" 1;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 6px;
}

.headline-e {
  @apply font-ysans-demi;
  font-feature-settings: "onum" 1, "pnum" 1;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 2rem;
  margin-bottom: 0.6rem;

  @screen desktop {
    font-size: 2.1rem;
    line-height: 2.4rem;
    margin-top: 3rem;
    margin-bottom: 1.4rem;
  }
}
