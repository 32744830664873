.details-sidebar {
  @apply desktop:w-2/6;
  flex: none;

  @screen desktop {
    margin-right: 4rem;
  }

  .info-content {
    @screen desktop {
      display: block;
    }
  }

  &__licenses,
  &__details,
  &__tags,
  &__caracteristics,
  &__related-families,
  &__scripts,
  &__extra {
    padding: 1.6rem 0;
    @apply border-t border-grey-light;

    @screen desktop {
      padding: 2rem 0;
    }
  }

  &__licenses {
    @apply flex flex-wrap;

    div {
      margin-right: 1.5rem;
    }

    span {
      @apply font-allumi;
      font-size: 1.2rem;
      line-height: 1.8rem;
      letter-spacing: 1px;
      color: #c2c2c2;
      text-transform: uppercase;

      @screen tablet {
        line-height: 2.7rem;
      }

      &:hover {
        @apply text-brown;
      }
    }
  }

  &__details {
    p {
      margin-bottom: 1.6rem;
      line-height: 2rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__tags,
  &__scripts,
  &__related-families {
    @apply hidden desktop:block;
  }

  &__caracteristics {
    @apply font-allumi;

    .formats {
      margin-bottom: 2rem;
      font-size: 1rem;

      span {
        margin-right: 2rem;
        letter-spacing: 0.1rem;
        @apply font-allumi;
      }

      .format {
        display: inline-block;
        margin-right: 1rem;
        line-height: 2;
        height: 2.2rem;
        padding: 0rem 0.9rem;
        text-transform: uppercase;
        @apply text-grey;
        @apply border;
      }
    }

    .content {
      font-size: 1.2rem;
      line-height: 2rem;
      @apply text-grey-dark;
    }
  }

  &__related-families {
    .detail-title {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    img {
      height: 2.7rem;
    }

    p:hover {
      filter: invert() url("#colorize-red");
    }
  }

  &__scripts {
    .detail-title {
      margin-bottom: 1rem;
    }

    .script {
      display: inline-block;
      margin-right: 1rem;
      font-size: 1rem;
      line-height: 2;
      letter-spacing: 1px;
      padding: 0.2rem 1rem;
      text-transform: uppercase;
      @apply font-allumi;
      @apply border;

      &.disabled {
        @apply text-grey;
      }
    }
  }

  .info-title {
    display: inline-flex;
    align-items: center;
    margin-bottom: 2.4rem;
    font-size: 1.5rem;
    line-height: 2rem;
    @apply font-ysans-demi;

    @screen desktop {
      display: none;
    }

    svg {
      width: 0.8rem;
      height: 0.5rem;
      margin-top: 0.8rem;
      margin-left: 1.2rem;
      transform: rotate(180deg);
    }
  }

  .detail-title {
    font-size: 1.2rem;
    line-height: 2rem;
    @apply font-allumi;
    @apply text-grey-dark;
  }

  .detail-web-specimen {
    font-size: 1.2rem;
    line-height: 2rem;
    @apply font-allumi;
  }

  /* States */
  &.has-revealed {
    .info-title svg {
      transform: rotate(0deg);
    }
  }
}
