.tabs {
}
.tabs-header {
  &.selected {
    @apply text-red;
  }
}

.tabs-item {
  display: none;

  &.selected {
    display: block;
  }
}
