.help-button {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  font-size: 1.1rem;
  line-height: 1;
  border-radius: 0.8rem;
  vertical-align: middle;
  text-align: center;
  padding: 1px;
  @apply font-allumi;

  @apply bg-white text-brown;
  @apply border border-brown;

  &:hover {
    cursor: help;
    @apply bg-brown text-white;
  }
}
