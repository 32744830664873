.product-item {
  & > a {
    display: block;
  }

  &-img-container {
    max-height: 100%;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s ease-in;
    @apply border border-grey-light;

    &:hover .product-item-img {
      opacity: 0;
    }

    @screen desktop {
      max-height: 26.4rem;
    }
  }

  &-badge {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 3;
    pointer-events: none;
  }

  &-img,
  &-img-hover {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &-img {
    @apply bg-white;
    position: relative;
    z-index: 2;
    transition: opacity ease 0.35s;
  }

  &-img-hover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &-name {
    @apply text-brown;
    margin-top: 2rem;

    svg {
      float: left;
      margin-top: 0.5rem;

      @screen desktop {
        margin-top: 1rem;
      }
    }
  }

  &-description {
    @apply hidden tablet:block;
    @apply italic text-grey-dark;
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: 0.04rem;
  }

  &-discount {
    font-style: italic;
    font-size: 1.4rem;
    line-height: 2.5rem;
    letter-spacing: 0.035rem;
    @apply text-red;

    @screen desktop {
      font-size: 1.6rem;
      line-height: 2.7rem;
      letter-spacing: 0.046rem;
    }
  }

  &:hover {
    .product-item-name,
    .product-item-description {
      @apply text-red;
    }

    .product-item-img-container {
      box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.6);
    }
  }

  /* States */
  &.is-updated .product-item-badge {
    color: #79a1ab;
    letter-spacing: 0.46px;
    font-style: italic;
  }

  &.is-new .product-item-badge svg {
    width: 4.5rem;
    height: 2.1rem;
  }
}
