.variations-item {
  @apply border-t border-grey-light;

  &__main {
    @apply flex justify-between;
  }

  &__info {
    flex: 1 1 100%;
    padding-top: 0.5rem;
    font-size: 1.2rem;
    letter-spacing: 0.03rem;
    line-height: 1.5rem;
    @apply font-allumi text-grey-dark;

    span {
      margin-right: 2px;
    }

    @screen tablet {
      font-size: 1.4rem;
      line-height: 2.7rem;
      padding-top: 1rem;
    }
  }

  .font-preview {
    font-size: 5.2rem;
    line-height: normal;
    height: 100%;
    padding-top: 0;
    padding-bottom: 2.8rem;
    overflow: hidden;
    max-width: 100%;
    white-space: pre;
    @apply text-black;

    input {
      @apply font-not-def;
      width: 100%;
      outline: none;
      line-height: 0.8;
    }

    @screen tablet {
      font-size: 7.2rem;
    }
  }

  &__price {
    flex: 0 0 auto;
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    @screen desktop {
      margin-left: 5rem;
      min-width: 8rem;
    }
  }

  &__action {
    flex: 0 0 auto;
    margin-left: 2rem;

    @screen desktop {
      margin-left: 5rem;
    }

    .add-checkbox-button {
      margin-top: -1px;
    }
  }

  &:last-of-type {
    @apply border-b;
  }

  &.is-selected {
    .font-preview {
      @apply text-red;
    }
  }
}
