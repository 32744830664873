.modal-glyph {
  & .modal__content {
    height: 100%;
  }

  & .modal__content > div {
    margin-top: 30px;
    padding: 10px;
    overflow: hidden;
  }

  & header {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px;
    @apply bg-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: calc(var(--navbar-height) + 1rem);

    & .logo {
      height: auto;

      & svg {
        width: 52px;
        height: 34px;
      }
    }

    & .close {
      display: flex;
      align-items: center;
      @apply text-grey-dark;

      span {
        display: none;
      }

      & svg {
        width: 18px;
        height: 18px;
        padding: 8px;
        box-sizing: content-box;
      }
    }
  }

  .glyph-content {
    height: calc(100vh - 61px);
    width: 100%;

    @screen desktop {
      height: 100%;
    }
  }

  .glyph-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    svg {
      width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;

      path {
        color: #606060;
        @apply fill-current;
      }

      @screen desktop {
        width: auto;
        height: 100%;
      }
    }
  }

  .svg-info {
    position: static;
    bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--grid-max-width);
    width: 100%;
    left: 0;
    right: 0;

    @screen desktop {
      position: absolute;
    }

    p {
      text-transform: uppercase;
      font-size: 1.2rem;
      line-height: 2rem;
      letter-spacing: 0.15rem;
      @apply font-ysans-demi;

      @screen desktop {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }

  .modal-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    border: 1px solid #c2c2c2;
    @apply bg-white;

    svg {
      width: 1.2rem;
      height: 1rem;
      margin: auto;
    }

    &--previous {
      left: 1rem;

      svg {
        transform: rotateZ(180deg);
      }
    }

    &--next {
      right: 1rem;
    }

    @screen desktop {
      width: 5rem;
      height: 5rem;

      svg {
        width: 2rem;
        height: 1.5rem;
      }

      &--previous {
        left: 7.6rem;
      }

      &--next {
        right: 7.6rem;
      }
    }
  }
}
