.about-designer-show {
  margin-bottom: 2rem;

  &.container {
    @apply mx-auto tablet:mx-7 desktop:mx-auto;
  }

  header {
    @apply mx-14 mb-14 tablet:mx-auto;

    p {
      @apply tablet:pt-3 desktop:pt-0;
    }

    @screen desktop {
      margin-bottom: 10.8rem;

      p {
        @apply w-2/6;
      }

      h1.main-title-alt {
        @apply w-4/6;
        margin-left: 5.5rem;
        line-height: 4rem;
      }
    }
  }

  &-details-sidebar {
    @apply tablet-portrait:w-2/3;
    @apply tablet:w-2/6;

    &__img {
      width: 100%;
      object-fit: cover;
      object-position: center;

      aspect-ratio: 300/220;

      @screen desktop {
        aspect-ratio: 377/260;
      }
    }

    &__infos,
    &__links {
      @apply border-t border-grey-light;
    }

    &__infos {
      .infos-title {
        font-size: 1.2rem;
        line-height: 2rem;
        @apply font-allumi;
        @apply text-grey-dark;
      }
    }

    &__links {
      @apply border-b border-grey-light;
      @apply font-allumi;
      text-transform: uppercase;
      font-size: 1.2rem;
      line-height: 1.5rem;
      letter-spacing: 0.1rem;
    }
  }

  &-details-main {
    @apply tablet:w-4/6;

    font-size: 1.4rem;
    line-height: 2.5rem;
    letter-spacing: 0.035rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2.6rem;
    @apply text-brown;

    @screen tablet {
      margin-top: 0;
    }

    @screen desktop {
      font-size: 1.8rem;
      line-height: 2.9rem;
      letter-spacing: 0.045rem;
      padding: 0;
      margin-left: 2.6rem;
    }
  }

  &-details-projects,
  &-details-typefaces,
  &-details-family-case-studies,
  &-details-articles {
    svg.icon-dropdown-arrow {
      width: 0.8rem;
      height: 0.5rem;
      margin-top: 0.8rem;
      margin-left: 1.2rem;
      transform: rotate(0deg);
      transition: transform 0.25s ease-out;
    }

    ul {
      margin-top: 3rem;
    }

    .show-all {
      @apply text-red;
      @apply font-allumi;
      flex-direction: row-reverse;
      font-size: 1.2rem;
      line-height: 2.7rem;
      cursor: pointer;
      display: flex;
      align-items: baseline;
      margin-top: 1rem;

      &:hover {
        @apply text-dark-red;
      }

      svg.icon-dropdown-arrow {
        width: 0.9rem;
        height: 0.6rem;
        margin-top: 0.8rem;
        margin-left: 0.8rem;
      }
    }

    &.has-revealed {
      button svg {
        transform: rotate(180deg);
      }
    }
  }

  &-details-projects,
  &-details-typefaces,
  &-details-family-case-studies,
  &-details-articles {
    @apply border-t border-grey-light;
    margin: 0 2rem;

    @screen desktop {
      margin: 0;
    }

    .typeface-content,
    .project-content,
    .case-study-content,
    .article-content {
      &[data-expand-state-value="false"] {
        .show-all {
          p:last-of-type {
            display: none;
          }

          .icon-dropdown-arrow {
          }
        }
      }

      &[data-expand-state-value="true"] {
        .show-all {
          p:first-of-type {
            display: none;
          }

          .icon-dropdown-arrow {
            transform: rotate(180deg);
          }
        }
      }
    }

    .typeface-content,
    .project-content {
      &[data-expand-state-value="false"] {
        .grid li:nth-child(n + 3) {
          display: none;
        }
      }

      @screen tablet {
        display: block;

        &[data-expand-state-value="false"] {
          .grid li:nth-child(n + 2) {
            display: block;
          }

          .grid li:nth-child(n + 4) {
            display: none;
          }
        }
      }

      @screen desktop {
        &[data-expand-state-value="false"] {
          .grid li:nth-child(n + 3),
          .grid li:nth-child(n + 4) {
            display: block;
          }

          .grid li:nth-child(n + 7) {
            display: none;
          }
        }
      }
    }

    .article-content {
      &[data-expand-state-value="false"] {
        ul:not(.grid) li:nth-child(n + 3),
        ul:not(.grid) li:nth-child(n + 4) {
          display: block;
        }

        ul:not(.grid) li:nth-child(n + 7) {
          display: none;
        }
      }
    }

    .case-study-content {
      &[data-expand-state-value="false"] {
        .grid li:nth-child(n + 2) {
          display: none;
        }
      }

      @screen tablet-portrait {
        &[data-expand-state-value="false"] {
          .grid li:nth-child(n + 2) {
            display: block;
          }

          .grid li:nth-child(n + 3) {
            display: none;
          }
        }
      }

      @screen tablet {
        display: block;
      }

      @screen desktop {
        &[data-expand-state-value="false"] {
          .grid li:nth-child(n + 3) {
            display: block;
          }

          .grid li:nth-child(n + 4) {
            display: none;
          }
        }
      }
    }
  }

  &-details-articles {
    @screen desktop {
      /* align with sidebar margin */
      width: calc(66.666667% - 2.6rem);
      margin-left: auto;
    }

    /* TODO: remove when hover on image will be added everywhere */
    .articles-item:hover {
      .articles-item__image {
        box-shadow: none;
      }
    }
  }
}
