.related-families {
  &-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.4rem;
    margin-bottom: 4rem;

    @screen desktop {
      margin-top: 2rem;
      margin-bottom: 6rem;
    }
  }

  .related-family {
    font-size: 2rem;
    line-height: 2.7rem;
    margin-bottom: 0.5rem;
    white-space: break-spaces;
    word-break: break-word;
    @apply text-grey;
    width: 100%;

    @screen tablet {
      width: auto;
    }

    &:not(:last-child) {
      margin-right: 2rem;

      @screen desktop {
        margin-right: 3rem;
      }
    }

    @screen desktop {
      font-size: 2.6rem;
      line-height: 3.5rem;
    }

    img {
      height: 2.5rem;

      @screen tablet {
        height: 2.7rem;
      }
    }

    &:hover {
      filter: invert() url("#colorize-red");
      @apply text-brown;
    }
  }
}
