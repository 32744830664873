.web-projects-index {
  &__header {
    @apply flex flex-row justify-between;
    @apply -mb-16 desktop:-mb-40;

    .left-side {
      @apply mb-14 desktop:mb-40;
    }

    .right-side {
      @apply mb-4 flex items-end text-right;
    }
  }

  & &__content {
    .list {
      li {
        width: 100%;
        position: relative;
        padding-bottom: 1.85rem;
        padding-top: 1.5rem;
        @apply border-b border-grey-light;

        &:first-child {
          @apply border-t;
        }

        .project__name {
          line-height: 2rem;
          @apply text-red;
        }

        .caption {
          @apply ml-4;
          @apply text-grey-dark;
          font-style: normal;

          @screen desktop {
            font-size: 1.2rem;
          }
        }

        .project__font {
          font-size: 1.2rem;
          line-height: 2rem;
          @apply text-grey-dark;

          @screen desktop {
            line-height: 1.6rem;
          }
        }

        .delete {
          position: absolute;
          top: -1px;
          right: 0;
          width: 2.7rem;
          height: 2.7rem;
          overflow: hidden;

          &:hover {
            svg {
              @apply text-grey-dark;
            }
          }
        }
      }
    }

    .list-actions {
      margin-top: 0.5rem;
      @apply flex justify-end;
      @apply font-allumi;

      a {
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }
  }

  & &__empty-list {
    p {
      @apply border-b border-t border-grey-light py-12 pl-8 text-grey;
      font-size: 1.4rem;

      @screen desktop {
        font-size: 1.6rem;
      }
    }
  }
}
