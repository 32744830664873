.users-edit {
  @apply mb-32;

  & &__account-informations-form,
  & &__billing-address-form,
  & &__password-form {
    h2.subtitle-1 {
      @apply mb-6 border-none pt-0;
    }

    .user-form {
      @apply tablet:grid tablet:grid-cols-2;
      grid-column-gap: var(--grid-gap);
    }
  }

  & &__billing-address-form {
    .right-side {
      textarea {
        @apply h-auto;

        min-height: 8.5rem;

        @screen desktop {
          min-height: 10rem;
        }
      }

      h3.subtitle-2 {
        margin-bottom: 1.7rem;

        @screen desktop {
          margin-bottom: 1.2rem;
        }
      }
    }
  }

  & &__form-footer {
    @apply flex flex-col text-right;
    @apply mb-24;

    .newsletters-agreement {
      @apply mb-8 text-grey-dark desktop:mb-12;
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.035rem;

      input {
        @apply ml-4;
      }

      @screen desktop {
        font-size: 1.6rem;
        letter-spacing: 0.04rem;
      }
    }
  }

  .faq-blocks {
    @apply mt-32;

    @screen tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    }

    @screen desktop {
      grid-column-gap: 3.8rem;
    }
  }
}
