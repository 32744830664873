.designers-list-item {
  &-img-container {
    max-height: 100%;
    position: relative;
    overflow: hidden;

    &:hover .product-item-img {
      opacity: 0;
    }

    @screen desktop {
      max-height: 26.4rem;
    }
  }

  &-img {
    width: 100%;
    object-fit: cover;
    object-position: center;

    aspect-ratio: 249/183;
  }

  &-infos {
    &-name {
      @apply text-brown;
    }

    &-role {
      @apply text-grey;
      @apply font-allumi;
      font-size: 1.2rem;

      @screen desktop {
        text-align: right;
        margin-top: 0;
      }
    }
  }

  &-projects {
    width: 100%;

    p.designers-projects-block-name {
      @apply hidden desktop:inline-block;
    }

    .designers-projects-block {
      width: calc(50% - 0.5rem);
      height: 100%;

      @screen desktop {
        width: calc(50% - 1.5rem);
      }
    }
  }

  a:hover {
    .designers-list-item-infos-name,
    .designers-list-item-infos-role {
      @apply text-red;
    }
  }
}
