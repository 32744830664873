.dashboards-artwork {
  @apply mb-32;

  &__header {
    @apply flex flex-row justify-between;
    @apply -mb-16 desktop:-mb-40;

    .left-side {
      @apply mb-14 desktop:mb-40;
    }

    .right-side {
      @apply mb-4 flex items-end text-right;
    }
  }

  &__empty_list {
    @apply mb-20;

    p {
      @apply border-b border-t border-grey-light py-12 pl-8 text-grey;
      font-size: 1.4rem;

      @screen desktop {
        font-size: 1.6rem;
      }
    }
  }
}
