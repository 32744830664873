.form {
  & fieldset {
    @apply mb-4;
  }

  & .form-input,
  & .form-text-area {
    @apply ml-0 w-full bg-grey-field font-mencken-text text-brown;
    outline: 0;
    font-size: 1.2rem;
    letter-spacing: 0.034rem;
    line-height: 2.7rem;
    padding: 0.8rem 1.6rem;

    @screen desktop {
      font-size: 1.4rem;
      letter-spacing: 0.04rem;
    }

    &:focus {
      @apply border-l-4 border-brown;
      padding-left: 1.2rem;
    }

    &::placeholder {
      @apply text-grey-dark text-opacity-70;
    }

    &.field-with-errors {
      @apply border-l-4 border-red;
      padding-left: 1.2rem;
    }
  }

  & input.form-input {
    min-height: 3.2rem;
    max-height: 3.2rem;

    @screen desktop {
      min-height: 3.6rem;
      max-height: 3.6rem;
    }
  }

  textarea.form-text-area {
    height: 12rem;
    min-height: 10rem;
    resize: vertical;

    -webkit-font-kerning: normal;
    font-kerning: normal;

    font-feature-settings: "kern" 1, "liga" 1, "onum" 1;
  }

  & input,
  & textarea {
    border-radius: 0;
  }

  & input[type="checkbox"],
  & input[type="radio"] {
    width: auto;
  }

  & .form-group {
    label {
      @apply hidden;
    }
  }

  .inline-flex .form-group + .form-group {
    margin-top: 0;
  }

  .inline-flex .form-group {
    flex: 1 1 50%;
  }

  .form-group.select-group {
    @apply bg-grey-field;
    padding: 0.8rem 1.6rem;
  }

  select.form-select {
    @apply w-full;

    &.field-with-errors {
      @apply border-red;
    }
  }
}

.button {
  @apply bg-red font-allumi text-white;
  border-radius: 0.8rem !important;
  padding: 0.8rem 2.5rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1rem;
  min-height: 2.4rem;
  letter-spacing: 0.083rem;
  text-align: center;
  min-width: 9.5rem;
  cursor: pointer;
  transition: transform 0.1s ease-in, background-color 0.1s ease-in;
  transform: perspective(1px) translateZ(0);

  &:hover {
    @apply bg-dark-red text-white;
    transform: scale3d(0.975, 0.975, 0.975) perspective(1px) translateZ(0);
  }
}

.button[disabled=] {
  @apply bg-grey;

  &:hover {
    @apply bg-grey-dark;
  }
}

select {
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  line-height: 1.2rem;
  height: 2.7rem;
  margin-right: 1rem;
  text-transform: uppercase;
  padding: 0 2.4rem 0 1.2rem;
  border-radius: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDUiPgogIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjM0MzQTM2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS4yNSIgZD0iTTEgMWwzIDMgMy0zIi8+Cjwvc3ZnPgo=);
  background-size: 0.8rem 0.6rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  appearance: none;
  cursor: pointer;
  @apply border border-grey bg-white font-allumi;
}
