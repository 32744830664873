.articles-show {
  width: 100vw;

  @screen tablet {
    width: auto;
  }

  &__content {
    .title {
      @apply mb-6 desktop:mb-14;
      @apply break-words;
      margin-left: 25px;
      letter-spacing: normal;

      @screen tablet-portrait {
        margin-left: calc(33.33333%);
        width: calc(66.66667%);
      }
    }

    .author {
      margin-bottom: 1.9rem;
      margin-left: 25px;

      @screen tablet-portrait {
        margin-left: calc(33.33333%);
        width: calc(66.66667%);
      }
    }

    .cover-image {
      @apply mt-8 mb-10 tablet-portrait:mt-16 tablet-portrait:mb-24;

      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      max-width: 100vw;
      width: 100vw;

      @screen tablet-portrait {
        max-width: 100%;
        width: 100%;
        position: static;
        margin-left: 0;
        margin-right: 0;
      }
    }

    .description {
      margin-left: 25px;

      @screen tablet-portrait {
        margin-left: calc(33.33333%);
        width: calc(66.66667%);
      }

      & > p.image-full-width img {
        margin-left: calc(-50vw - (25px / 2)); /* remove the margin-left added in .description */

        @screen tablet-portrait {
          margin-left: 0;
        }
      }

      & > p:first-of-type {
        &::first-letter {
          float: left;
          margin-right: 10px;
          margin-bottom: 5px;
          font-size: 87px;
          line-height: 52px;

          @screen desktop {
            font-size: 103px;
            line-height: 64px;
          }
        }
      }
    }

    footer {
      font-size: 12px;
      line-height: 25px;
      letter-spacing: 0.035rem;
      padding-top: 0.6rem;
      margin-left: 25px;
      margin-top: 2.5rem;
      @apply font-allumi text-grey-dark;
      @apply border-t border-t-grey-light;

      @screen tablet-portrait {
        margin-left: calc(33.33333%);
        width: calc(66.66667%);
      }

      @screen desktop {
        margin-top: 4.2rem;
        font-size: 16px;
        line-height: 27px;
      }
    }
  }

  &__related {
    margin-top: 8.4rem;

    @screen desktop {
      margin-top: 10rem;
    }

    h2 {
      padding-top: 0.5rem;
      margin-bottom: 2.4rem;
      @apply font-ysans-demi;
      @apply border-t border-t-grey-light;

      @screen desktop {
        padding-top: 1.8rem;
      }
    }

    .related-item {
      &__image {
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        transition: box-shadow 0.2s ease-in;
        width: 100%;
      }

      &__title {
        margin-top: 8px;

        @apply font-ysans-demi text-brown desktop:mb-[16px];
      }

      &__excerpt {
        @apply text-grey-dark;

        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.35px;

        @screen desktop {
          font-size: 1.8rem;
          line-height: 2.9rem;
          letter-spacing: 0.045rem;
        }
      }

      &:hover {
        & > * {
          @apply text-red;
        }

        .related-item__image {
          box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.6);
        }
      }
    }

    div + div {
      margin-top: 4rem;

      @screen desktop {
        margin-top: 6rem;
      }
    }
  }

  &--gazette {
    .title {
      @apply font-mencken-compress uppercase;
      font-size: 4.3rem;
      line-height: 0.93;

      @screen tablet-portrait {
        font-size: 7.2rem;
      }

      @screen tablet {
        font-size: 10rem;
      }
    }

    .description {
      & > p:first-of-type {
        &::first-letter {
          @apply font-ysans-demi;
        }
      }
    }

    @-moz-document url-prefix() {
      .description {
        & > p:first-of-type {
          &::first-letter {
            margin-top: 7px;

            @screen desktop {
              font-size: 104px !important;
            }
          }
        }
      }
    }
  }

  &--news {
    .title {
      @apply font-ysans-demi;
      font-size: 2.2rem;
      line-height: 1.09;

      @screen tablet-portrait {
        font-size: 3.6rem;
      }

      @screen tablet {
        font-size: 5rem;
      }
    }

    .description {
      & > p:first-of-type {
        &::first-letter {
          @apply font-mencken-compress;

          font-size: 88px;
          line-height: 54px;

          @screen desktop {
            font-size: 108px;
            line-height: 65px;
          }
        }
      }
    }

    @-moz-document url-prefix() {
      .description {
        & > p:first-of-type {
          &::first-letter {
            margin-top: 8px;

            @screen desktop {
              font-size: 105px !important;
            }
          }
        }
      }
    }
  }
}
