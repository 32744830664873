.main {
  padding-top: var(--navbar-height);
}

.container {
  @apply mx-14 tablet:mx-7 desktop:mx-auto;
  max-width: calc(var(--grid-max-width) + var(--grid-gap) * 2);
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
}

.grid {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
}

.wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto auto;
}
