.glyph {
  width: 5rem;
  height: 5rem;
  color: #606060;
  text-align: center;

  &--active {
    background-color: #f6f6f6;
  }

  svg {
    height: 100%;
    width: 100%;

    path {
      color: #606060;
      @apply fill-current;
    }
  }

  &:hover {
    cursor: pointer;
  }

  @screen desktop {
    width: 9.6rem;
    height: 9.6rem;
    padding-top: 1rem;
  }
}
