.order-show {
  &__header {
    @apply flex flex-row justify-between;

    margin-bottom: -2.2rem;

    @screen desktop {
      margin-bottom: -3.2rem;
    }

    .left-side {
      @screen tablet {
        max-width: 47%;
      }

      @screen desktop {
        max-width: 33.33%;
        padding-right: 1.85rem;
      }

      h1 {
        @apply mb-2;
      }

      p {
        @apply text-grey-dark;
      }
    }

    .right-side {
      @apply hidden flex-shrink-0 text-right desktop:block;

      p.price {
        @apply flex items-baseline;

        span:first-child {
          @apply mr-4 not-italic text-grey-dark tablet:mr-16;

          font-size: 1.2rem;
        }

        span:last-child {
          @apply mb-8 font-allumi;

          font-size: 2.4rem;
          line-height: 2rem;
        }
      }

      p.credit-invoice-link {
        @apply mt-6 font-allumi;

        font-size: 1.2rem;
        line-height: 2rem;
        letter-spacing: 0.03rem;
        text-align: right;
      }
    }
  }

  & &__main {
    .informations-block {
      @apply mb-20 flex flex-col tablet:flex-row tablet:justify-between desktop:mb-32;

      .order-informations,
      .order-invoicing {
        @apply w-full border-t border-grey-light;

        @screen tablet {
          width: 47%;
        }

        h2 {
          @apply mb-6 inline-flex align-baseline;

          svg.icon-dropdown-arrow {
            width: 0.8rem;
            height: 0.5rem;
            margin-top: 0.9rem;
            margin-left: 0.8rem;
            transform: rotate(180deg);
          }
        }

        p:not(.ui-small-text) {
          @apply text-grey-dark;
          line-height: 2rem;

          font-size: 1.4rem;

          @screen desktop {
            font-size: 1.6rem;
          }
        }

        .content-block {
          @screen tablet {
            display: block;
          }
        }

        &.has-revealed {
          h2 svg {
            transform: rotate(0deg);
          }
        }
      }

      .order-informations {
        @apply tablet:pt-6;

        h2 {
          @apply tablet:hidden;
        }

        .text-group {
          @apply mb-8;
        }
      }

      .order-invoicing {
        h2 {
          @apply mb-12 desktop:mt-6;

          svg.icon-dropdown-arrow {
            @apply tablet:hidden;
          }
        }
      }
    }

    .order-summary {
      .licenses {
        .licenses-name {
          font-size: 1.2rem;
          line-height: 2.7rem;
          letter-spacing: 0.08rem;
          color: #9b9b9b;
          @apply mb-6 inline-flex items-center font-allumi uppercase;

          .help-button {
            @apply ml-4 hidden;
          }

          @screen desktop {
            font-size: 1.6rem;
            line-height: 1.9rem;
            letter-spacing: 0.04rem;
            margin-bottom: 0.8rem;

            .help-button {
              @apply inline-block;
            }
          }
        }

        ul {
          @apply border-t border-grey-light;
        }

        .typeface-group {
          margin-bottom: 0;
          @apply mb-0 flex items-center border-b border-grey-light py-4;

          @screen desktop {
            padding-top: 1.6rem;
            padding-bottom: 1.6rem;
          }

          .name {
            font-size: 1.4rem;
            line-height: 2rem;
            letter-spacing: 0.035rem;

            @screen desktop {
              font-size: 1.6rem;
              letter-spacing: 0.04rem;
            }
          }

          .format {
            letter-spacing: 0.1rem;
            font-size: 1.4rem;
            @apply ml-4 font-allumi uppercase;
          }

          .extra {
            @apply order-3 mt-1 w-full font-allumi desktop:ml-4 desktop:mt-0 desktop:w-auto;
            font-size: 1.2rem;
            line-height: 2rem;
            letter-spacing: 0.04rem;
            color: #c2c2c2;

            @screen desktop {
              letter-spacing: 0.03rem;
              order: initial;
            }
          }

          .price-block {
            margin-left: auto;

            .product-item-discount {
              @apply mr-6;
            }

            .old-price {
              @apply font-allumi text-grey line-through;
            }

            .price {
              font-size: 1.4rem;
              line-height: 2rem;
              margin-left: auto;
              @apply font-allumi;

              @screen desktop {
                font-size: 1.6rem;
              }
            }
          }

          fieldset {
            @apply my-4 w-full;
            order: 4;

            input + input {
              margin-top: 1.2rem;
            }

            @screen desktop {
              margin-top: 1.2rem;
              display: inline-flex;

              input + input {
                margin-top: 0;
                margin-left: 1rem;
              }
            }
          }
        }

        .license-subtotal {
          @apply mb-12 text-right;
        }

        .subtotal,
        .subtotal + p {
          font-size: 1.2rem;
          line-height: 1.6rem;
          letter-spacing: 0.05rem;
          @apply font-allumi;
        }

        .subtotal {
          @apply mt-2.5 text-grey-dark;
        }
      }

      .checkout-total {
        @apply my-8 mt-16 tablet:w-max desktop:mb-16;
        margin-left: auto;

        dl {
          @apply flex flex-wrap font-allumi text-grey-dark;
          line-height: 2rem;
        }

        dt {
          @apply mb-2 w-4/6 text-right;
        }

        dd {
          @apply w-2/6 text-right;
        }

        .total {
          padding-top: 1.4rem;
          @apply mt-8 border-t border-grey-light;

          span:first-of-type {
            @apply text-black;
          }

          dd {
            font-size: 2rem;
            @apply text-brown;

            @screen tablet {
              font-size: 2.6rem;
            }
          }
        }
      }

      &__notes {
        @apply my-32;

        p {
          @apply text-grey-dark;
          letter-spacing: 0.03rem;
        }
      }

      .admin-conditions {
        .markdown-block {
          * {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
