.account-user-menu {
  @apply tablet:mb-32 desktop:mr-8 desktop:w-2/6;

  .menu-button {
    @apply absolute border-b border-grey-light pb-6 desktop:hidden;

    top: 8.6rem;
    right: 1rem;
    left: 1rem;

    svg.icon-dropdown-arrow {
      width: 0.8rem;
      height: 0.5rem;
      margin-top: 0.8rem;
      margin-left: 1.2rem;
    }
  }

  .menu {
    @apply hidden;

    @apply desktop:block;
    @screen desktop {
      padding-right: 1.85rem;
    }

    ul {
      @apply flex-none border-t border-grey-light py-5 font-allumi uppercase tablet:py-7;

      font-size: 1rem;
      line-height: 1.8rem;
      letter-spacing: 0.08rem;

      &:last-child {
        @apply border-b border-grey-light;
      }

      @screen tablet {
        font-size: 1.2rem;
        line-height: 2rem;
        letter-spacing: 0.1rem;
      }
    }

    li {
      @apply ml-4 tablet:ml-8;

      a {
        @apply text-grey-dark;
      }

      &.active a,
      & a:hover {
        @apply text-black;
      }
    }
  }

  &.is-opened {
    .menu-button .icon {
      transform: rotate(180deg);
    }

    .menu {
      display: block;
      position: absolute;
      top: 12.1rem;
      left: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      background-color: rgba(255, 255, 255, 0.95);
      z-index: 10;
    }
  }
}
