.exclusive-goody {
  @apply mt-6;

  &-img-container {
    max-height: 100%;
    position: relative;
    overflow: hidden;
    @apply border border-grey-light;

    @screen desktop {
      max-height: 26.4rem;
    }
  }

  &-img {
    @apply bg-grey-light;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &-caption {
    @apply font-mencken-text text-brown;
    @apply mt-6;
    font-size: 1.4rem;
    line-height: 2.7rem;
  }

  &:hover {
    .push-item-caption {
      /* TODO: Add the sexy animation */
      @apply text-red;
    }
  }
}
