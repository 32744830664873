.typeface-segments-show {
  /* .font-info {

    @screen desktop {
      max-width: 38rem;
      margin-right: 4rem;
      margin-top: 7rem;
      margin-bottom: 5rem;
    }
  } */

  &:not(.typeface-segments-details) {
    .slider {
      .carousel {
        .carousel-btn-prev {
          @screen large-screen {
            left: calc((100vw - var(--grid-max-width)) / 2 - 70px);
          }
        }

        .carousel-btn-next {
          @screen large-screen {
            right: calc((100vw - var(--grid-max-width)) / 2 - 70px);
          }
        }
      }
    }
  }

  .segment-tabs {
    margin-bottom: 3rem;

    @screen desktop {
      margin-bottom: 4rem;
    }
  }

  .add-to-cart-price {
    @apply inline-flex items-center;
    @screen desktop {
      display: initial;
    }
  }

  .font-size-slider {
    @screen desktop {
      input {
        max-width: 18rem;
      }
    }
  }

  .offers {
    margin-bottom: 3rem;

    @screen desktop {
      margin-bottom: 6rem;
    }
  }
}
