.typeface-family-case-studies-show {
  .container {
    @apply mx-auto tablet:mx-7 desktop:mx-auto;
  }

  .segment-header {
    @apply mx-14 mb-14 tablet:mx-7 desktop:mx-auto;

    p {
      @apply tablet:pt-3 desktop:pt-0;
    }

    @screen desktop {
      margin-bottom: 10.8rem;

      h1.main-title-alt {
        margin-left: 5.5rem;
        line-height: 4rem;
      }
    }
  }

  .typeface-family-case-study__slider {
    &__main img,
    &__thumbnails img {
      @apply bg-grey-light;
    }

    &__main {
      @apply w-full flex-none;

      img {
        aspect-ratio: 9/4;
        object-fit: cover;

        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: 100vw;
        width: 100vw;

        @screen tablet-portrait {
          max-width: 100%;
          width: 100%;
          position: static;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    &__thumbnails {
      @apply mt-8;
      @apply flex flex-wrap gap-8;
    }

    &__thumbnail {
      @apply flex-shrink-0;
    }
  }

  .details-main {
    @screen desktop {
      width: calc(66.666667% - 4rem);
    }
  }

  .related-fonts {
    @apply ml-auto flex flex-wrap gap-16;

    @screen desktop {
      width: calc(66.666667% - 4rem);
      margin-right: 0;
    }
  }

  .related-fonts-item {
    max-width: 16.8rem;

    img {
      @apply bg-grey-light;
      @apply mb-2;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.035rem;
      min-height: 4rem;
      @apply text-grey-dark;
      @apply mb-4;

      @screen desktop {
        font-size: 16px;
      }
    }

    &:hover {
      p {
        @apply text-dark-red;
      }
    }
  }
}
