.designers-projects {
  &-block {
    aspect-ratio: 121/84;

    @screen desktop {
      aspect-ratio: 168/117;
    }

    &:hover {
      .designers-projects-block-name {
        @apply text-red;
      }
    }

    .svg-placeholder {
      aspect-ratio: 121/84;

      @screen desktop {
        aspect-ratio: 168/117;
      }
    }

    &-img {
      @apply border border-grey-light;

      &.icon-designer-project-placeholder {
        height: 100%;
        width: 100%;

        @apply bg-grey-field;
      }
    }

    &-name {
      @apply font-mencken-text italic text-grey-dark;

      font-size: 1.2rem;
      line-height: 1.6rem;

      @screen tablet {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}
