.errors-field {
  @apply absolute -ml-10;

  margin-top: 1.1rem;

  svg.icon-warning-hover {
    width: 1.25rem;
    height: 1.25rem;
    cursor: help;

    @screen desktop {
      width: 1.5rem;
      height: 1.5rem;
    }

    &:hover {
      @apply text-dark-red;
    }
  }
}
