.account-carts-show {
  &__header {
    @apply flex flex-row items-center justify-between;

    @screen desktop {
      margin-bottom: -3.2rem;
    }

    .left-side {
      h1 {
        @apply mb-2;
      }

      p {
        @apply text-grey-dark;
      }
    }

    .right-side {
      @apply text-right font-allumi;
      @apply hidden tablet:block;

      font-size: 1.2rem;

      p:last-of-type:not(:only-of-type) {
        @apply mt-4;

        span.help-button {
          @apply mr-4;
        }

        a {
          @apply text-grey-dark;

          &:hover {
            @apply text-black;
          }
        }
      }
    }
  }

  .cart-content {
    .cart-admin-conditions {
      place-items: flex-start;

      div {
        @apply w-full;
      }
    }

    .cart-extras {
      .faq-blocks {
        width: 100%;
      }
    }
  }
}

form .account-carts-show__header {
  @screen desktop {
    margin-bottom: -4.25rem;
  }
}
