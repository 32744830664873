.cart-banner {
  @apply fixed z-10 w-full bg-grey;
  left: 0;
  height: var(--cart-banner-height);

  &.cart-open {
    top: 0;
  }

  & &__content {
    @apply mx-auto flex items-end text-brown hover:text-black;
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;

    @apply flex w-full;

    span.logo {
      height: auto;

      & svg {
        width: 5.2rem;
        height: 3.4rem;
      }
    }

    .right-content {
      @apply flex items-center;
    }

    .icons {
      @apply ml-8 flex items-center;

      svg.icon-cart {
        width: 2rem;
        height: 2rem;
      }

      svg.icon-dropdown-arrow {
        @apply ml-2;

        width: 1.2rem;
        height: 0.68rem;
        transform: rotate(180deg);
      }

      svg.icon-close {
        width: 1.8rem;
        height: 1.8rem;
        padding: 0.8rem;
        box-sizing: content-box;
      }
    }

    .subtitle {
      @apply ml-6;
      line-height: 2rem;
    }

    a.button {
      @apply px-6;
      flex: none;
    }
  }

  /* States */

  &.cart-open {
    height: var(--navbar-height);
    top: 0;

    .cart-banner__content {
      @apply items-center justify-between;

      .subtitle {
        margin-bottom: 0.5rem;
      }

      .icons {
        svg.icon-dropdown-arrow {
          transform: rotate(0deg);
        }

        svg.icon-cart,
        svg.icon-dropdown-arrow {
          @apply hidden;
        }

        svg.icon-close {
          @apply block;
        }
      }
    }
  }

  &:not(.cart-open) {
    bottom: 0;

    .cart-banner__content {
      @apply flex-row-reverse;

      span.logo {
        @apply hidden;
      }

      .icons {
        @apply flex-row-reverse;

        svg.icon-cart,
        svg.icon-dropdown-arrow {
          @apply block;
        }

        svg.icon-close {
          @apply hidden;
        }
      }
    }
  }
}
