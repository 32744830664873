.dashboards-artwork,
.dashboards-show {
  .artwork-licenses {
    &__list {
      li {
        @apply border-b border-grey-light;

        padding-top: 1.85rem;
        padding-bottom: 1.5rem;

        &:first-child {
          @apply border-t border-grey-light;
        }

        p:first-child {
          span.caption {
            @apply ml-4;
          }
        }

        span.format {
          letter-spacing: 0.1rem;
          font-size: 1.4rem;
          @apply ml-4 font-allumi uppercase;
        }

        span.caption {
          @apply text-grey-dark;
          font-style: normal;

          @screen desktop {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
