.modal-newsletter {
  & .modal__content {
    height: 100%;
  }

  & .modal__content > div {
    margin-top: 1rem;
    padding: 1rem;
    overflow: auto;

    @screen tablet {
      margin-top: 3rem;
    }
  }

  & header {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1rem;
    @apply bg-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: calc(var(--navbar-height) + 1rem);

    & .logo {
      height: auto;

      & svg {
        width: 5.2rem;
        height: 3.4rem;
      }
    }

    & .close {
      display: flex;
      align-items: center;
      @apply text-grey-dark;

      span {
        display: none;
      }

      & svg {
        width: 1.8rem;
        height: 1.8rem;
        padding: 0.8rem;
        box-sizing: content-box;
      }
    }
  }

  & .newsletter-content {
    aside .helper {
      line-height: 2.7rem;
    }

    .content p {
      font-size: 1.8rem;
      line-height: 2.9rem;
      letter-spacing: 0.045rem;
    }
  }
}
