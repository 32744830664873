.family-case-study-item {
  & > a {
    display: flex;
    flex-direction: column;
  }

  &-img-container {
    max-height: 100%;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s ease-in;
    @apply border border-grey-light;

    @screen desktop {
      max-height: 26.4rem;
    }
  }

  &-img,
  &-img-hover {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &-img {
    @apply bg-white;
    position: relative;
    z-index: 2;
    transition: opacity ease 0.35s;
    aspect-ratio: 380/266;
  }

  &-name {
    @apply text-brown;
    margin-top: 2rem;

    svg {
      float: left;
      margin-top: 0.5rem;

      @screen desktop {
        margin-top: 0.75rem;
      }
    }
  }

  &-agency {
    @apply hidden tablet:block;
    @apply italic text-grey-dark;
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: 0.04rem;
  }

  &-families a {
    line-height: 2.7rem;
    margin-left: 1rem;

    &:first-of-type {
      margin-left: 0;
    }
  }

  & > a:hover {
    .family-case-study-item-name,
    .family-case-study-item-agency {
      @apply text-red;
    }
  }
}
