.segment-header {
  @apply mx-auto desktop:flex desktop:justify-between;

  .font-info {
    margin-bottom: 1rem;
    @apply desktop:w-2/6;
  }

  .segment-tabs {
    @apply desktop:w-4/6;
  }

  @screen desktop {
    .font-info {
      margin-right: 4rem;
      margin-bottom: 4rem;
    }
  }
}
