.font-pairings {
  h2 {
    margin-bottom: 2rem;
  }

  .font-pairing {
    display: none;

    &:first-of-type {
      display: block;
    }
  }

  @screen desktop {
    h2 {
      margin-bottom: 3.4rem;
    }

    .font-pairing {
      display: block;
    }
  }

  .font-pairing {
    .product-item-name {
      font-size: 1.4rem;
      line-height: 2.7rem;
      letter-spacing: 0.035rem;
      margin-top: 1rem;
      @apply font-mencken-text italic text-grey-dark;

      @screen desktop {
        font-size: 1.6rem;
        line-height: 2.7rem;
        letter-spacing: 0.035rem;
      }

      svg {
        margin-top: 0.7rem;
      }
    }
  }
}
