.typeface-group {
  margin-bottom: 4rem;
  @apply flex-wrap tablet:flex;

  &__header {
    width: 100%;
    @apply flex items-baseline border-b border-brown py-4;

    .name {
      font-size: 2rem;
      line-height: 3.2rem;
      letter-spacing: 0.5px;
      margin-right: 1rem;
      @apply text-brown;

      img {
        height: 3.2rem;
      }

      @screen tablet {
        margin-left: 33.33%;
      }

      &:hover {
        @apply text-red;

        img {
          filter: invert() url("#colorize-red");
        }
      }
    }

    .product-item-discount {
      line-height: 2.5rem;
    }

    .delete {
      margin-left: auto;
      font-size: 1.2rem;
      line-height: 2.7rem;
      @apply font-allumi text-grey;

      @screen tablet {
        font-size: 1.4rem;
        line-height: 2rem;
      }

      &:hover {
        @apply text-grey-dark;
      }
    }
  }

  &__license {
    @apply flex-wrap items-start tablet:w-2/6;

    .license {
      height: auto;
      overflow: hidden;

      @apply pt-4 tablet:mr-8 tablet:py-0;

      @screen tablet {
        padding: 1.2rem 0;
      }

      header {
        @apply flex-wrap items-baseline tablet:items-center;
        @apply tablet:mb-4;

        .current-license,
        .licensing-options {
          width: 50%;
          font-size: 1.2rem;
          line-height: 1.8rem;
          letter-spacing: 0.8px;
          @apply font-allumi uppercase text-grey;

          @screen tablet {
            line-height: 2.7rem;
            margin-bottom: 0.5rem;
          }
        }

        .current-license {
          @apply text-grey-dark;

          @screen tablet {
            font-size: 1.6rem;
            line-height: 3.2rem;
            letter-spacing: 0.4px;
          }
        }

        .licensing-options {
          text-align: right;
          @apply inline-flex items-center justify-end font-allumi;

          @screen tablet {
            display: none;
          }

          .icon {
            width: 0.8rem;
            height: 0.5rem;
          }
        }

        .licenses {
          a {
            color: inherit;
          }
        }
      }

      .usage-multiplier,
      .license-limitation {
        p {
          font-size: 1.2rem;
          line-height: 2.7rem;
          @apply font-allumi text-grey;
        }

        select {
          min-width: 33%;
          margin-bottom: 0.5rem;
        }

        &:not(:first-child) {
          select {
            margin-bottom: 1.8rem;
          }
        }
      }

      .helper {
        font-size: 1.2rem;
        line-height: 2rem;
        margin-bottom: 1.3rem;
        @apply font-allumi text-grey-dark;
      }

      .artwork-license {
        display: inline-flex;
        align-items: center;
        font-size: 1.2rem;
        line-height: 2.7rem;
        @apply font-allumi text-grey;

        .help-button {
          margin-left: 1rem;
          line-height: 1;
          @apply hidden tablet:block;
        }
      }

      .add-another-artwork-license {
        font-size: 1.2rem;
        line-height: 2rem;
        @apply font-allumi;

        @screen tablet {
          font-size: 1rem;
        }

        @screen desktop {
          font-size: 1.2rem;
        }

        a {
          display: inline-flex;
          align-items: center;
        }

        svg.icon-arrow-right {
          margin-top: 0.2rem;
        }
      }
    }

    .license-selector {
      @apply hidden tablet:flex;
      @apply mt-2 tablet:mt-0;

      &__item {
        @apply border font-allumi uppercase;
        z-index: 1;

        &:not(.border-grey) {
          z-index: 3;
        }

        &:last-child {
          margin-left: -0.1rem;
        }

        a,
        span {
          color: inherit;
          font-family: inherit;
          font-size: 1rem;
          line-height: 2.7rem;
          letter-spacing: 0.1rem;
          @apply p-4;

          &:hover {
            @apply text-black;
          }
        }
      }
    }

    &.has-revealed {
      .license {
        height: auto;
        overflow: visible;
      }

      .licensing-options .icon {
        transform: rotate(180deg);
      }

      .license-selector {
        @apply flex;
      }
    }
  }

  &__formats {
    @apply w-full flex-1;

    &__list {
      @apply pt-4 tablet:py-0;
    }

    &__item {
      padding: 1.2rem 0;
      font-size: 1.4rem;
      @apply flex justify-between;
      @apply border-b border-grey-light;

      @screen tablet {
        font-size: 1.6rem;
        line-height: 3.2rem;
      }

      .price-with-discount {
        margin-left: auto;
      }

      .product-item-discount {
        margin-right: 3rem;
      }

      .old-price {
        @apply font-allumi;
        @apply text-grey line-through;
      }

      .price {
        @apply font-allumi;
        @apply ml-auto mr-10;
        @apply text-grey-dark;
      }

      .delete {
        flex: 0 0 auto;
        align-self: flex-start;
        margin-top: -1.3rem;

        .icon {
          width: 2.7rem;
          height: 2.7rem;
          transition: color 0.2s ease-in;
        }

        &:hover {
          svg.icon.icon-delete {
            @apply text-grey-dark;
          }
        }
      }

      &:first-of-type {
        @apply border-t tablet:border-t-0;

        @screen tablet {
          .delete {
            margin-top: -1.2rem;
          }
        }
      }
    }

    .add-another-license {
      padding: 1.2rem 0;
      @apply w-full;
      @apply border-b border-grey-light tablet:border-b-0;

      button {
        @apply inline-flex items-center;
      }

      p {
        font-size: 1.2rem;
        line-height: 2rem;
        @apply font-allumi text-grey-dark;
      }

      .icon {
        width: 0.8rem;
        height: 0.6rem;
        margin-left: 1rem;
      }

      ul {
        @apply inline-flex items-center;
      }

      ul li {
        font-size: 1.2rem;
        line-height: 2rem;
        letter-spacing: 0.4px;
        margin-right: 1rem;
        @apply font-allumi;

        a {
          @apply uppercase text-grey-dark;

          &:hover {
            @apply text-dark-red;
          }
        }
      }

      &.has-revealed {
        .icon {
          transform: rotateZ(180deg);
        }
      }
    }

    .total-price {
      font-size: 1.7rem;
      line-height: 2rem;
      margin-top: 7px;
      margin-left: auto;
      flex-shrink: 0;
      @apply text-right font-allumi text-brown;

      @screen tablet {
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: 0.5px;
      }
    }
  }
}
