@import "_variables.css";
@import "_fonts.css";
@import "_text.css";
@import "_form.css";
@import "_layout.css";
@import "_utilities.css";
@import "_print.css";

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  vertical-align: baseline;
  text-size-adjust: 100%;
  font-size: 62.5%;
}

body {
  @apply text-brown;
  min-height: 100%;

  @apply font-mencken-text;
  font-size: 16px;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* Reset */
button {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
}
