.dashboards-show {
  @apply mb-32;

  .subtitle-1 {
    @apply border-t-0 pb-6 pt-0;
  }

  p.actions {
    @apply mt-2 font-allumi;

    font-size: 1.2rem;
    line-height: 2rem;
    letter-spacing: 0.03rem;
    text-align: right;
  }

  .artwork-licenses,
  .dashboards-library {
    @apply mt-10;
  }

  .faq-blocks {
    @apply mt-32;

    @screen tablet {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    }

    @screen desktop {
      grid-column-gap: 3.8rem;
    }
  }
}
