.web-projects-new {
  & &__content {
    &__header {
      header {
        .heading {
          font-size: 2.2rem;
          line-height: 2rem;
          @apply font-mencken-head;
          @apply inline-flex justify-between;

          @screen desktop {
            font-size: 3.2rem;
            line-height: 2rem;
          }
        }

        p {
          margin: 2rem 0;
          font-size: 1.6rem;
          line-height: 2.7rem;
          @apply text-grey-dark;

          @screen desktop {
            font-size: 1.8rem;
            line-height: 2.9rem;
            letter-spacing: 0.45px;
          }
        }
      }
    }

    .form {
      .form_submit {
        @apply flex justify-end;
      }
    }
  }
}
