.web-projects-show {
  &__header {
    h1 {
      @apply mb-2;
    }

    p {
      @apply text-grey-dark;
    }
  }

  & &__content {
    &__header {
      header {
        margin-bottom: 3rem;
        @apply flex items-baseline justify-between;

        .heading {
          font-size: 2.2rem;
          line-height: 2rem;
          @apply font-mencken-head;
          @apply inline-flex justify-between;

          @screen desktop {
            font-size: 3.2rem;
            line-height: 2rem;
          }
        }

        .edit {
          font-size: 1.2rem;
          line-height: 2rem;
          @apply text-grey-dark;
          @apply font-allumi;
          @apply mx-4;

          &:hover {
            @apply text-brown;
          }
        }

        .delete {
          margin-left: auto;
          font-size: 1.2rem;
          line-height: 2rem;
          @apply font-allumi;
        }
      }

      form {
        .form_submit {
          @apply flex justify-end;
        }
      }
    }

    a.button {
      @apply px-6;
    }

    .subheading {
      margin-top: 4rem;
      margin-bottom: 1.6rem;
      font-size: 2.2rem;
      line-height: 2rem;
      @apply font-ysans-demi;
      @apply inline-flex justify-between;

      @screen desktop {
        font-size: 2.1rem;
        line-height: 2.4rem;
      }
    }

    .list {
      li {
        width: 100%;
        position: relative;
        padding-bottom: 1.8rem;
        padding-top: 1.8rem;
        @apply border-b border-grey-light;

        &:first-child {
          @apply border-t;
        }

        p {
          font-size: 1.4rem;
          line-height: 2rem;
          display: inline;
        }

        span.format {
          letter-spacing: 0.1rem;
          font-size: 1.3rem;
          @apply ml-4 font-allumi uppercase;

          @screen desktop {
            margin-right: 1rem;
          }
        }

        span.extras {
          font-size: 1.2rem;
          line-height: 2rem;
          display: block;
          @apply font-allumi;
          @apply text-grey-dark;
          @apply w-full;

          @screen desktop {
            width: auto;
          }
        }

        .invoice {
          font-size: 1.2rem;
          line-height: 1.6rem;
          @apply block;
          @apply font-allumi;
        }

        .create {
          position: absolute;
          top: -1px;
          right: 0;
          width: 2.7rem;
          height: 2.7rem;

          svg {
            width: 2.7rem;
            height: 2.7rem;
          }

          .icon-add-checkbox {
            color: #c2c2c2;
          }

          &:hover {
            cursor: pointer;

            .icon-add-checkbox {
              @apply text-grey-dark;
            }
          }
        }

        .delete {
          position: absolute;
          top: -1px;
          right: 0;
          width: 2.7rem;
          height: 2.7rem;
          margin-right: 0;
          overflow: hidden;

          &:hover {
            svg {
              @apply text-grey-dark;
            }
          }
        }
      }
    }

    &__webfonts {
      p.empty_web_project {
        @apply ml-4 text-grey;
      }
    }

    .list-actions {
      margin-top: 1.6rem;
      @apply flex justify-end;
      @apply font-allumi;
    }

    &__helper {
      margin-top: 4.8rem;

      p {
        font-size: 1.6rem;
        line-height: 2.7rem;
        @apply text-grey-dark;

        @screen desktop {
          font-size: 1.8rem;
          line-height: 2.9rem;
          letter-spacing: 0.45px;
        }

        &.small {
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0.5px;

          @screen desktop {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }

    &__library {
      margin-bottom: 8rem;
    }

    .faq-blocks {
      display: none;

      @screen tablet {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2rem;
      }
    }
  }
}
