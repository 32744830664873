.help-index {
  @apply mt-8 tablet:mt-28;

  &.container {
    @apply mx-auto tablet:mx-7 desktop:mx-auto;
  }

  .help-header {
    margin-bottom: 3.8rem;
  }

  &__content {
    @screen tablet-portrait {
      margin-left: calc(33.33333% + 2rem);
      width: calc(66.66667% - 4rem);
    }

    .search-results {
      font-size: 1.9rem;
      line-height: 2rem;
      margin-bottom: 2.6rem;
      @apply font-ysans-demi;

      span {
        @apply text-red;
      }

      @screen desktop {
        font-size: 1.6rem;
        line-height: 2rem;
        margin-bottom: 2.1rem;
      }
    }

    .description {
      padding-left: 2rem;
      padding-right: 2rem;
      font-size: 1.4rem;
      line-height: 2.5rem;
      letter-spacing: 0.04rem;
      margin-bottom: 4.1rem;

      @screen desktop {
        font-size: 1.8rem;
        line-height: 2.9rem;
        padding: 0;
        margin-bottom: 4.6rem;
      }
    }

    .categories {
      margin-bottom: 4rem;

      button:hover {
        @apply text-dark-red desktop:text-brown;
      }

      .category {
        font-size: 1.5rem;
        line-height: 2rem;
        padding-top: 0.4rem;
        @apply border-t border-grey-light;
        @apply font-ysans-demi;

        @screen desktop {
          font-size: 1.8rem;
          padding-top: 1rem;
        }

        & > button {
          @apply w-full;
          @apply inline-flex items-center;
          margin-bottom: 1rem;

          svg {
            width: 0.8rem;
            height: 0.5rem;
            margin-left: 1rem;
            transform: rotate(0deg);
          }

          @screen desktop {
            cursor: default;

            svg {
              display: none;
            }
          }
        }

        &.has-revealed {
          & > button svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    .pages {
      margin-bottom: 3rem;

      @screen desktop {
        margin-bottom: 5rem;
        display: block;
      }

      .page {
        margin-bottom: 1rem;
        @apply w-full;
        @apply inline-flex items-start;
        @apply text-brown;

        button {
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
          margin-top: 0.3rem;
          flex-shrink: 0;
          @apply text-grey-dark;
          @apply rounded-full border border-solid border-grey-dark;
          @apply hover:border-brown hover:bg-brown hover:text-white;

          @screen desktop {
            margin-top: 0.8rem;
          }

          svg {
            width: 1rem;
            height: 1.2rem;
            transform: rotateZ(-90deg);
            @apply m-auto;
          }
        }

        a {
          color: inherit;
          font-size: 1.9rem;
          line-height: 2.7rem;
          @apply hover:text-dark-red;

          @screen desktop {
            font-size: 2.8rem;
            line-height: 3.8rem;
          }
        }

        &.has-revealed {
          button {
            @apply text-white;
            @apply border-brown bg-brown;

            svg {
              transform: rotateZ(90deg);
            }
          }
        }
      }
    }

    .sections {
      margin-top: 1.2rem;
      margin-bottom: 1rem;
      @apply w-full;

      .section {
        margin-bottom: 1.2rem;

        a {
          font-size: 1.3rem;
          line-height: 2.7rem;

          @screen desktop {
            font-size: 1.5rem;
            line-height: 2.8rem;
          }
        }
      }
    }
  }
}
