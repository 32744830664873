.select-all-button {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #c2c2c2;
  cursor: pointer;
  align-self: center;
  @apply hidden tablet:block;
  @apply font-allumi;

  input {
    @apply hidden;
  }

  input:checked ~ span:first-of-type {
    display: none;
  }

  input:checked ~ span:last-of-type {
    display: inline;
  }

  span:hover {
    @apply text-grey-dark;
  }

  span:last-of-type {
    @apply desktop:ml-8;
    display: none;
  }
}
