.orders-index,
.dashboards-show {
  .download-invoices__list {
    li {
      @apply flex justify-between border-b border-grey-light py-8;

      &:first-child {
        @apply border-t;
      }

      span.caption {
        @apply text-grey-dark;
        font-style: normal;

        @screen desktop {
          font-size: 1.2rem;
        }
      }

      p:first-child {
        span:first-child {
          font-size: 1.4rem;

          @screen desktop {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }

        span:last-child {
          @apply block desktop:mt-2;
        }
      }

      p:last-child {
        @apply min-w-max;

        a.button {
          @apply ml-4 desktop:ml-8;

          &:hover {
            @apply text-white;
          }
        }
      }
    }
  }

  .download-invoices__empty_list {
    @apply mb-20;

    p {
      @apply border-b border-t border-grey-light py-12 pl-8 text-grey;

      font-size: 1.4rem;

      @screen desktop {
        font-size: 1.6rem;
      }
    }
  }
}
