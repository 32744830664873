.cart {
  .cart-button {
    min-height: 2.7rem;
    @apply mb-4;

    @screen tablet {
      font-size: 1.2rem;
      line-height: 2rem;
      padding: 0 2rem;
    }
  }

  .cart-links,
  .checkout-links {
    .help-button {
      margin-right: 1rem;
      line-height: 1;
    }
  }

  .update {
    appearance: none;
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 0;
    background-color: transparent;
    min-height: auto;
    cursor: pointer;
    font-weight: normal;
    @apply text-red;
    @apply font-allumi;

    &:hover {
      @apply text-dark-red;
    }
  }

  p.inline-links {
    font-size: 1.2rem;
    line-height: 1.8rem;
    @apply font-allumi;
  }

  & > form > header {
    @apply mt-6 mb-10 flex justify-between;
  }

  & &-actions {
    @apply text-right;

    p {
      font-size: 1.2rem;
      line-height: 1.8rem;
      @apply font-allumi;
    }

    .price {
      font-size: 2rem;
      line-height: 2rem;

      @apply mb-6;
    }

    input {
      min-height: 2.7rem;
    }
  }

  & &-content {
    .empty-cart {
      margin-bottom: 7rem;
    }
  }

  & &-total {
    margin-top: 1.4rem;
    margin-bottom: 7rem;
    @apply border-t border-black;
    @apply font-allumi;

    &__vat,
    &__code,
    &__discount {
      line-height: 2.7rem;

      @apply flex items-center justify-between;
      @apply py-4;

      p {
        margin-right: 1rem;
        text-align: right;
        @apply w-1/2 tablet:w-full;

        @screen tablet {
          font-size: 1.8rem;
        }
      }

      span {
        @screen tablet {
          width: 30%;
          text-align: right;
        }
      }
    }

    &__vat {
      p {
        @apply text-grey;
      }
    }

    &__code {
      input.discount-code-input {
        @apply border border-grey text-center;
        width: 14.5rem;
        padding: 0 1.6rem;
        outline: 0;

        &::placeholder {
          @apply text-grey-dark text-opacity-70;
        }

        &:focus {
          @apply border-l-4 border-brown;
          padding-left: 1.3rem;
        }

        &.field-with-errors {
          @apply border-l-4 border-red;
          padding-left: 1.3rem;
        }
      }

      .discount-input {
        .errors-field {
          @apply w-auto text-left;
          margin-left: -2rem;
          margin-top: 0.8rem;

          @screen tablet {
            margin-left: -2.5rem;
            margin-top: 0.7rem;
          }
        }
      }
    }

    &__discount {
      span {
        @apply text-grey-dark;
      }
    }

    .ts_cart_price {
      font-size: 2rem;
      line-height: 2rem;

      @screen tablet {
        font-size: 2.4rem;
        line-height: 2rem;
      }
    }

    .cart-actions {
      @apply mt-12 text-right;

      .checkout-links {
        @apply tablet:mt-8;
      }
    }
  }

  & &-admin-conditions {
    @apply my-32;

    @screen tablet {
      display: flex;
      place-items: flex-end;
      flex-direction: column;
    }

    div {
      @screen tablet {
        width: 66%;
      }
    }

    .markdown-block {
      * {
        margin-top: 0.5rem;
      }
    }
  }

  & &-extras {
    @apply mb-16;

    @screen tablet {
      display: flex;
      place-items: flex-end;
      flex-direction: column;
    }

    .faq-blocks {
      @apply mt-28 tablet:mt-20 desktop:mt-28;

      @screen tablet {
        display: grid;
        width: 66%;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2rem;
      }
    }

    .exclusive-goodies-block {
      @screen tablet {
        width: 66%;
      }

      ul {
        @screen tablet {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 2rem;
        }
      }
    }
  }
}
